import React, { FC, useEffect, useState } from 'react';
import { Alert, message, Modal, Select } from 'antd';
import * as apiService from '@/service';
import { useFlow, CallNext, GetNext, useUrlQueryState, useSuperState } from '@@/react-hooks';
import { SimilarSpus } from '../verify/component/similar-spus';
import { StyleIdSpuProduct } from '@/interface/label';
import { PureAntdLoading } from '@/ui/pure-antd-loading';
import { LoadableChangeAntdButton, LoadableAntdButton } from '@/ui/loadable-antd-button';
import { useAccountInfo } from '@/store/account';
import { LabelLayout, LabelLayoutHeader } from '../layout';
import { SpuDisplay } from '../spu-display';
import { NoMoreLabeling } from '../no-more-labeling';
import { GetSimilarSpuData } from '@/service/interface';
import { LabelUrlQuery } from '@/interface/url';
import { Gender, LabelType, LabelSourceEnum } from '@/enum';
import { MaybePromise } from '@@/util-types';
import commonStyles from '../../common.module.less';
import { ALL_VALUE } from '@/const';
import { useHistory } from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { record_work } from '../../index';
import { useCategoryByGenderCascaderOptions } from '@/hooks/use-category-cascader-options';
import { LoadingOutlined } from '@ant-design/icons';

const awaitCombineConfirm = msg =>
  new Promise<boolean>((resolve, reject) => {
    let comfirm_msg = '商品非同一个品牌，是否强制合并?';
    if (msg == 'Both spu has user interactions') {
      comfirm_msg = '商品存在用户行为，是否强制合并?';
    }
    Modal.confirm({
      title: comfirm_msg,
      icon: <ExclamationCircleFilled />,
      content: '',
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    });
  });

/**
 * submission data for correction labeling
 */
interface VerificationSubmission {
  spuIdsToCombine: number[]; // spuIds to combine
  forceCombine?: boolean;
  category2?: string;
}
export type Action =
  | {
      type: 'previous';
      payload: VerificationSubmission;
    }
  | {
      type: 'submit';
      payload: VerificationSubmission;
    }
  | {
      type: 'combine';
      payload: VerificationSubmission;
    }
  | {
      type: 'filterCategory2';
      payload: VerificationSubmission;
    };

interface LoadInfo {
  loading: boolean;
  error: boolean;
  deleteSpu: number[];
  currentIndex: number;
}

const CombineStyleIdCore: FC<{
  loadInfo: LoadInfo;
  combineProduct: StyleIdSpuProduct;
  dispatch(action: Action): MaybePromise<void>;
}> = ({ loadInfo, combineProduct, dispatch, ...filterProps }) => {
  // 标注数据
  const [labelState, setLabelState] = useSuperState<VerificationSubmission>({
    spuIdsToCombine: [],
    category2: '',
  });
  const [combineLoading, setCombineLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);

  const handleKeyDown = event => {
    if (event.key.toUpperCase() === 'B') {
      dispatch({ type: 'previous', payload: labelState });
    }
    if (event.key.toUpperCase() === 'N') {
      setNextLoading(true);
      const result = dispatch({ type: 'submit', payload: labelState });
      if (result instanceof Promise) {
        result.finally(() => {
          setLabelState({ spuIdsToCombine: [] });
          setNextLoading(false);
        });
      }
    }
    if (event.key.toUpperCase() === 'C' && !event.ctrlKey && !event.altKey && !event.metaKey) {
      setCombineLoading(true);
      const result = dispatch({ type: 'combine', payload: labelState });
      if (result === undefined) {
        setCombineLoading(false);
      }
      if (result instanceof Promise) {
        result.finally(() => {
          setLabelState({ spuIdsToCombine: [] });
          setCombineLoading(false);
        });
      }
    }
  };

  // initialize labeling state while spu mutated
  useEffect(() => {
    setLabelState({
      spuIdsToCombine: [],
      category2: '',
    });
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const category = sessionStorage.getItem('styleId_category')
    ? sessionStorage.getItem('styleId_category').split(',')
    : '';
  const [gender, category1] = category as [Gender, string, string];
  const categorysCascaderOptionsState = useCategoryByGenderCascaderOptions(gender);
  let category2CascaderOptionsState = [];
  const category1CascaderOptionsState = [];
  for (const options of categorysCascaderOptionsState.data) {
    category1CascaderOptionsState.push({ value: options['value'], label: options['label'] });
    const c1 = category1;
    if (options['value'] == c1) {
      category2CascaderOptionsState = options['children'];
    }
  }

  const onChangeCategory2 = (category2: string) => {
    setLabelState(prev => ({
      ...prev,
      category2,
    }));
    dispatch({ type: 'filterCategory2', payload: labelState });
  };

  // 内容节点
  const contentNode = (() => {
    const { deleteSpu } = loadInfo;
    const onChangeSelectedSpuIds = (spuIdsToCombine: number[]) =>
      setLabelState(prev => ({
        ...prev,
        spuIdsToCombine: spuIdsToCombine.filter(id => !deleteSpu.includes(id)),
      }));

    if (labelState.category2) {
      const filterCategoryList = [];
      for (const product of combineProduct.keywordProducts) {
        if (product.category2 && product.category2 == labelState.category2) {
          filterCategoryList.push(product);
        }
      }
      combineProduct.keywordProducts = filterCategoryList;
    }
    const { keywordProducts, commonPrefix } = combineProduct;
    const spuId = labelState.spuIdsToCombine.slice(-1)[0];
    const spu = keywordProducts.filter(value => (value.id == spuId ? value : ''))[0];
    sessionStorage.setItem('styleId', commonPrefix);

    return (
      <>
        <div className={commonStyles.left_of_2_2}>
          <div className={commonStyles.vertical_container}>
            <header className={commonStyles.header}>
              <h3 className={commonStyles.title}>当前商品</h3>
              <span className={commonStyles.header_addon}>
                {labelState.spuIdsToCombine.length == 0 ? (
                  <button disabled>clear</button>
                ) : (
                  <button
                    onClick={() => {
                      setLabelState({ spuIdsToCombine: [] });
                    }}
                  >
                    clear(已选中 {labelState.spuIdsToCombine.length} 项)
                  </button>
                )}
              </span>
            </header>
            {spu ? <SpuDisplay data={spu} /> : <br></br>}
          </div>
        </div>

        <div className={commonStyles.right_of_6}>
          <SimilarSpus
            data={combineProduct}
            selectedSpuIds={labelState.spuIdsToCombine}
            onChangeSelectedSpuIds={onChangeSelectedSpuIds}
          />
        </div>
      </>
    );
  })();

  return (
    <LabelLayout>
      <LabelLayoutHeader {...filterProps} type={LabelType.Verify}>
        <div className={commonStyles.sort_by}>
          <Select placeholder="过滤category2" allowClear onChange={onChangeCategory2}>
            {category2CascaderOptionsState.map(data => (
              <Select.Option key={data.label} value={data.value}>
                {data.value}
              </Select.Option>
            ))}
          </Select>
        </div>
        {loadInfo.currentIndex >= 1 ? (
          <LoadableAntdButton onClick={() => dispatch({ type: 'previous', payload: labelState })}>
            {' '}
            上一批(B)
          </LoadableAntdButton>
        ) : null}
        <span style={{ width: '10px' }}></span>
        <LoadableChangeAntdButton
          onClick={() => dispatch({ type: 'submit', payload: labelState })}
          onChange={() => {
            setLabelState({ spuIdsToCombine: [] });
          }}
        >
          {nextLoading ? <LoadingOutlined /> : null} 下一批(N)
        </LoadableChangeAntdButton>
        <span style={{ width: '10px' }}></span>
        <LoadableChangeAntdButton
          onClick={() => dispatch({ type: 'combine', payload: labelState })}
          onChange={() => {
            setLabelState({ spuIdsToCombine: [] });
          }}
        >
          {combineLoading ? <LoadingOutlined /> : null} 合并(C)
        </LoadableChangeAntdButton>
      </LabelLayoutHeader>
      {contentNode}
    </LabelLayout>
  );
};

interface VerificationState {
  queue: StyleIdSpuProduct[];
  index: number;
  deleteSpu: number[];
}

export const CombineStyleIdLabeler: FC = () => {
  const accountInfo = useAccountInfo();
  const history = useHistory();

  const [{ gender, c1, c2, brand }] = useUrlQueryState<LabelUrlQuery>();

  const [
    {
      data: { queue, index, deleteSpu },
      loading,
      ready,
      error,
    },
    dispatch,
  ] = useFlow<VerificationState, Action | void>(
    {
      queue: [],
      index: 0,
      deleteSpu: [],
    },
    function* ({ call, put, get, getCount }, action) {
      if (action && action.type == 'filterCategory2') {
        const {
          index: currentIndex,
          queue: currentQueue,
          deleteSpu: spuDelete,
        }: GetNext<VerificationState> = yield get();
        const deleteSpu = spuDelete;
        yield put({
          queue: currentQueue,
          index: currentIndex,
          deleteSpu: deleteSpu,
        });
        return;
      }
      if (action && action.type == 'previous') {
        const {
          index: currentIndex,
          queue: currentQueue,
          deleteSpu: spuDelete,
        }: GetNext<VerificationState> = yield get();
        const nextQueue = currentQueue;
        const deleteSpu = spuDelete;
        // 前面还有的话就向前一个
        if (nextQueue[currentIndex - 1]) {
          yield put({
            queue: nextQueue,
            index: currentIndex - 1,
            deleteSpu: deleteSpu,
          });
          return;
        }
        return;
      }
      if (action && action.type == 'submit') {
        const {
          index: currentIndex,
          queue: currentQueue,
          deleteSpu: spuDelete,
        }: GetNext<VerificationState> = yield get();
        const nextQueue = currentQueue;
        const deleteSpu = spuDelete;
        const { similarId } = currentQueue[currentIndex];
        try {
          yield call(apiService.postSimilarSpuData, {
            similarId,
          });
        } catch (e) {
          message.error(e?.msg || e?.message || '提交失败，请重试');
          return;
        }
        record_work(LabelType.StyleId, JSON.stringify({ similarId }), gender, c1, c2);
        // 后面还有的话就向后一个
        if (nextQueue[currentIndex + 1]) {
          yield put({
            queue: nextQueue,
            index: currentIndex + 1,
            deleteSpu: deleteSpu,
          });
          return;
        }
      }

      if (action && action.type == 'combine') {
        // 提交
        const {
          index: currentIndex,
          queue: currentQueue,
          deleteSpu: spuDelete,
        }: GetNext<VerificationState> = yield get();
        const { spuIdsToCombine } = action.payload;
        const deleteSpu = spuDelete;
        if (spuIdsToCombine.length) {
          if (spuIdsToCombine.length < 2) {
            message.error('当前没有可合并商品,请重新选择');
            return;
          }
          const spuId = spuIdsToCombine.slice(-1)[0];
          const spuIdsToCombineOther = spuIdsToCombine.slice(0, -1);
          record_work(LabelType.Combine, JSON.stringify(spuIdsToCombine), gender, c1, c2);
          let forceCombine = false;
          let forceCombineBrand = false;
          const spusToDelete = yield call(async () => {
            const list: number[] = [];
            let spuId0 = spuId;
            // async reduce
            for (const spuId1 of spuIdsToCombineOther) {
              const baseQuery = {
                source: LabelSourceEnum.STYLEID_DRAG,
                spuId0,
                spuId1,
                forceCombine: forceCombine,
                forceCombineBrand: forceCombineBrand,
              };
              const force_combine = async baseQuery => {
                try {
                  const {
                    data: { toKeep, toDelete, prioritySpuId },
                  } = await apiService.postCombineProduct({
                    ...baseQuery,
                  });
                  if (toDelete == prioritySpuId) {
                    // 如果toDelete优先级更高, 保留toDelete的spu信息，keep的id
                    const randomId = Math.floor(Math.random() * Math.pow(10, 9));
                    const currentProduct = currentQueue[currentIndex]['similarProducts'];
                    currentProduct.filter(spu => (spu.id == toKeep ? (spu.id = randomId) : null));
                    currentProduct.filter(spu => (spu.id == toDelete ? (spu.id = toKeep) : null));
                    currentProduct.filter(spu => (spu.id == randomId ? (spu.id = toDelete) : null));
                  }
                  list.push(toDelete);
                  spuId0 = toKeep;
                } catch (e) {
                  if (e && (e.msg == 'Both spu has user interactions' || e.msg == 'Both spu are not the same brand')) {
                    const result = await awaitCombineConfirm(e.msg);
                    if (result) {
                      // 强制合并
                      if (e.msg == 'Both spu has user interactions') {
                        forceCombine = true;
                        forceCombineBrand = true;
                        baseQuery['forceCombine'] = true;
                        baseQuery['forceCombineBrand'] = true;
                      } else {
                        forceCombine = false;
                        forceCombineBrand = true;
                        baseQuery['forceCombine'] = false;
                        baseQuery['forceCombineBrand'] = true;
                      }
                      await force_combine(baseQuery);
                    } else {
                      return list;
                    }
                  } else {
                    message.error(e?.msg || '提交失败，请重试');
                    return list;
                  }
                }
              };
              await force_combine(baseQuery);
            }
            return list;
          });
          currentQueue[currentIndex]['similarProducts'] = currentQueue[currentIndex]['similarProducts']
            .filter(spu => !spusToDelete.includes(spu.id))
            .map(item => ({ ...item }));
          for (const spu_id of spusToDelete) {
            deleteSpu.push(spu_id);
          }
          message.success('合并已完成');
        } else {
          message.error('请选择需合并商品');
          return;
        }
        yield put({
          queue: currentQueue,
          index: currentIndex,
          deleteSpu: deleteSpu,
        });
        return;
      }

      // 拉取新数据
      const { data }: CallNext<GetSimilarSpuData> = yield call(apiService.getSimilarSpuData, {
        gender,
        category1: c1,
        category2: c2 === ALL_VALUE ? undefined : c2,
        brand,
      });

      yield put({
        queue: data.similarData,
        index: 0,
        deleteSpu: deleteSpu,
      });
    }
  );

  useEffect(() => {
    dispatch();
    sessionStorage.setItem('Tabs_number', '5');
    sessionStorage.setItem('record_time', '0');
    sessionStorage.setItem('listener', '0');
  }, []);

  if (queue.length == 0) {
    if (loading) {
      return <PureAntdLoading size="large" tip="正在获取标注数据..." />;
    }

    if (error) {
      console.log('error', error);
      const errorDescription = error?.msg || error?.data || '未知错误';
      return <Alert message="获取标注数据错误" showIcon description={errorDescription} type="error" />;
    }

    if (!ready) {
      // 只会在初始时刻出现的状态
      return null;
    }
  }

  if (!queue[index]) {
    return (
      <LabelLayout>
        <LabelLayoutHeader type={LabelType.StyleId} />
        <NoMoreLabeling />
      </LabelLayout>
    );
  }
  const currentItem = {
    keywordProducts: queue[index]['similarProducts'],
    similarId: queue[index]['similarId'],
    commonPrefix: queue[index]['commonPrefix'],
  };
  const loadInfo = { loading: loading, error: error, deleteSpu: deleteSpu, currentIndex: index };

  return <CombineStyleIdCore loadInfo={loadInfo} combineProduct={currentItem} dispatch={dispatch} />;
};
