import React, { FC, useCallback, useContext, useRef, useEffect } from 'react';
import { Form, Cascader, Button, Select, FormInstance, SelectProps } from 'antd';
import { StyleIdFormValues } from './interface';
import styles from './home.module.less';
import { homeContext } from './context';
import { Gender, LabelType } from '@/enum';
import { url } from '@/url';
import { useHistory } from 'react-router-dom';
import { BaseOptionType } from 'antd/lib/cascader';
import { useFlow, CallNext } from '@@/react-hooks';
import * as apiService from '@/service';
import { GetDesigners } from '@/service/interface';
import { useCategory1CascaderOptions } from '@/hooks/use-category-cascader-options';

export const CombineStyleIdForm: FC = () => {
  const { commonFormItemProps, categoryCascaderOptionsState, genderDesignerOptionsDictState } = useContext(homeContext);
  const category = sessionStorage.getItem('styleId_category')
    ? sessionStorage.getItem('styleId_category').split(',')
    : '';
  const brand =
    sessionStorage.getItem('styleId_brand') == 'undefined'
      ? undefined
      : sessionStorage.getItem('styleId_brand')
      ? sessionStorage.getItem('styleId_brand').split(',')
      : undefined;

  const [designerOptionsState, dispatch] = useFlow(
    [] as {
      brand: string;
    }[],
    function* ({ call, put, cancel }, category: BaseOptionType) {
      if (!category) return;
      yield cancel();
      yield put([]);
      const [gender, category1, category2] = category as [Gender, string, string];
      const { data }: CallNext<GetDesigners> = yield call(apiService.getDesigners);
      yield put(
        (data[gender] || []).map(brand => ({
          brand,
        }))
      );
    }
  );

  const formRef = useRef<FormInstance>();

  useEffect(() => {
    dispatch(formRef.current.getFieldsValue().category);
  }, []);

  const onValuesChange = useCallback(changes => {
    if (changes.category) {
      dispatch(changes.category);
    }
    const form = formRef.current;
    const { category, brand } = form.getFieldsValue();
    const gender: Gender = category?.[0];
    if (gender && brand && !genderDesignerOptionsDictState.data[gender].includes(brand)) {
      form.setFieldsValue({
        brand: brand,
      });
    }
  }, []);

  const history = useHistory();
  const onStartCombine = useCallback(
    ({ category: [gender, c1, c2], brand }: StyleIdFormValues) => {
      history.push(url.label(LabelType.StyleId, { gender, c1, c2, brand: brand || undefined }));
    },
    [history]
  );
  const category1CascaderOptionsState = useCategory1CascaderOptions();

  return (
    <Form<StyleIdFormValues>
      ref={formRef}
      initialValues={{
        category: category ? category : undefined,
        brand: brand,
      }}
      onValuesChange={onValuesChange}
      className={styles.form}
      size="large"
      onFinish={onStartCombine}
    >
      <Form.Item
        {...commonFormItemProps}
        label="类别"
        name="category"
        required
        shouldUpdate={() => true}
        rules={[{ required: true, message: '请选择类别' }]}
      >
        <Cascader placeholder="请选择商品类别" options={category1CascaderOptionsState.data} />
      </Form.Item>
      <Form.Item<StyleIdFormValues> label="品牌" name="brand" {...commonFormItemProps}>
        <Select
          optionLabelProp="value"
          loading={designerOptionsState.loading}
          showSearch
          filterOption={true}
          placeholder="选择需要指定审核的品牌名称"
          allowClear
        >
          <Select.Option value="">全部</Select.Option>
          {designerOptionsState.data.map(({ brand }) => (
            <Select.Option key={brand} value={brand}>
              <div className={styles.designer_option}>
                <span>{brand}</span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Button
        htmlType="submit"
        type="primary"
        size="large"
        className={styles.button}
        onClick={() => {
          sessionStorage.setItem('listener', '0'), sessionStorage.setItem('record_time', '0');
        }}
      >
        开始合并
      </Button>
    </Form>
  );
};
