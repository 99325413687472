import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Form, DatePicker, Button, Spin, Select, Input, Table, Image } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Moment } from 'moment';
import { CallNext, useFlow, useFlowEffect } from '@@/react-hooks';
import * as apiService from '@/service';
import { useAccountInfo } from '@/store/account';
import { UserRole } from '@/enum';
import { url } from '@/url';
import { ExportLabelDetails } from '@/service/interface';
import { GetLabelerList } from '@/service/interface';

import styles from './report.module.less';

interface FormValues {
  range: [Moment, Moment];
  labeler: string;
  spu_id: number;
}

export const ReportDetailsView: FC = () => {
  const account = useAccountInfo();

  const labelerListState = useFlowEffect([] as string[], function* ({ put, call }) {
    const { data }: CallNext<GetLabelerList> = yield call(apiService.getLabelerList);
    yield put(data);
  }, []);

  const [{ data, loading }, onOk] = useFlow('', function* ({ put, call }, { range, labeler, spu_id }: FormValues) {
    const [start, end] = range;
    const { data }: CallNext<ExportLabelDetails> = yield call(apiService.exportLabelDetails, {
      start_date: start.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      end_date: end.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      labeler: labeler,
      spu_id: spu_id,
    });
    yield put(JSON.stringify(data));
  });

  if (account.userStatus === UserRole.Annotator) {
    return <Redirect to={url.home()} />;
  }

  const columns = [
    {
      title: 'spu id',
      dataIndex: 'reversible_product_id',
      key: 'reversible_product_id',
    },
    {
      title: '名称',
      dataIndex: 'reversible_product_name',
      key: 'reversible_product_name',
      width: 200,
    },
    {
      title: '价格',
      dataIndex: 'reversible_product_price',
      key: 'reversible_product_price',
    },
    {
      title: '图片',
      dataIndex: 'reversible_image_url',
      key: 'reversible_image_url',
      render: image => <Image src={image} />,
      width: 100,
    },
    {
      title: '错误id',
      dataIndex: 'error_product_id',
      key: 'error_product_id',
    },
    {
      title: '错误名称',
      dataIndex: 'error_product_name',
      key: 'error_product_name',
      width: 200,
    },
    {
      title: '错误价格',
      dataIndex: 'error_product_price',
      key: 'error_product_price',
    },
    {
      title: '错误图片',
      dataIndex: 'error_image_url',
      key: 'error_image_url',
      render: image => <Image src={image} />,
      width: 100,
    },
    {
      title: '关键词',
      dataIndex: 'keyword',
      key: 'keyword',
    },
    {
      title: '类型',
      dataIndex: 'error_type',
      key: 'error_type',
      render: type => type == 0 ? <span>classify</span> : <span>verify</span>,
    },
    {
      title: '标注员',
      dataIndex: 'labeled_by',
      key: 'labeled_by',
    },
    {
      title: '审核员',
      dataIndex: 'verified_by',
      key: 'verified_by',
    },
    {
      title: '时间',
      dataIndex: 'create_time',
      key: 'create_time',
    },
  ];

  return (
    <div className={styles.report}>
      <Form preserve={false} className={styles.form} onFinish={onOk}>
        <Link className={styles.back_link} to={url.home()}>
          <LeftOutlined /> 返回首页
        </Link>
        <Form.Item required rules={[{ required: true, message: '请先选择日期范围' }]} name="range">
          <DatePicker.RangePicker placeholder={['开始日期', '结束日期']} />
        </Form.Item>
        <span style={{ 'width': '20px' }}></span>
        <Form.Item label="spuId" name="spu_id"  >
          <Input placeholder="请填写spuId" required />
        </Form.Item>
        <span style={{ 'width': '20px' }}></span>
        <Form.Item label="账号" name="labeler" >
          <Select placeholder="选择标注账号" loading={labelerListState.loading} allowClear>
            {labelerListState.data.map(labeler => (
              <Select.Option key={labeler} value={labeler}>
                {labeler}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <footer>
          <Button htmlType="submit" type="primary" className={styles.button}>
            点击查询
          </Button>
        </footer>
      </Form>
      <Spin spinning={loading}>
        <div className={styles.content}>
          <Table dataSource={data ? JSON.parse(data) : []} columns={columns} rowKey={() => Math.random()} />;
        </div>
      </Spin>
      <div />
    </div>
  );
};