import React, { FC } from 'react';
import { Typography, Form, Input, Button, FormItemProps, message } from 'antd';
import { useCallback } from 'react';
import * as apiService from '@/service';
import styles from './index.module.less';
import { accountActions, useAccountState } from '@/store/account';

const { Title } = Typography;

interface LoginFormValues {
  username: string;
  password: string;
}

const FORM_ITEM_COMMON_PROPS: FormItemProps = {
  labelAlign: 'left',
  labelCol: {
    span: 6,
  },
};

export const Login: FC = () => {
  const dispatchAccountAction = useAccountState()[1];

  const onLogin = useCallback(
    async ({ username, password }: LoginFormValues) => {
      try {
        await apiService.login({
          username,
          password,
        });
        message.success('登录成功');
        dispatchAccountAction(accountActions.query());
      } catch (e) {
        message.error(e?.msg || '登录失败');
      }
    },
    [dispatchAccountAction]
  );

  return (
    <div className={styles.home}>
      <div className={styles.form_wrapper}>
        <Title level={2}>请登录</Title>
        <Form<LoginFormValues> size="large" onFinish={onLogin}>
          <Form.Item
            {...FORM_ITEM_COMMON_PROPS}
            label="用户名"
            name="username"
            rules={[{ required: true, message: '请输入用户名' }]}
          >
            <Input placeholder="请输入用户名" />
          </Form.Item>
          <Form.Item
            {...FORM_ITEM_COMMON_PROPS}
            label="密码"
            name="password"
            rules={[{ required: true, message: '请输入密码' }]}
          >
            <Input.Password />
          </Form.Item>
          <Button htmlType="submit" type="primary" size="large" className={styles.button}>
            登录
          </Button>
        </Form>
      </div>
    </div>
  );
};
