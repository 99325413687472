import { ALL_VALUE } from '@/const';
import * as apiService from '@/service';
import { GetCategories } from '@/service/interface';
import { uppercaseFirstLetter } from '@/util/format';
import { CallNext, useFlowEffect } from '@@/react-hooks';
import { BaseOptionType } from 'antd/lib/cascader';

export const useCategoryCascaderOptions = () =>
  useFlowEffect(
    [] as BaseOptionType[],
    function* ({ put, call }) {
      const { data }: CallNext<GetCategories> = yield call(apiService.getCategories);
      yield put(
        Object.entries(data).map(([gender, item]) => ({
          value: gender,
          label: uppercaseFirstLetter(gender),
          children: Object.entries(item).map(([value, subCategories]) => ({
            value,
            label: uppercaseFirstLetter(value),
            children: [
              {
                value: ALL_VALUE,
                label: '全部',
              },
              ...subCategories.map(subValue => ({
                value: subValue,
                label: uppercaseFirstLetter(subValue),
              })),
            ],
          })),
        }))
      );
    },
    []
  );

export const useCategory1CascaderOptions = () =>
  useFlowEffect(
    [] as BaseOptionType[],
    function* ({ put, call }) {
      const { data }: CallNext<GetCategories> = yield call(apiService.getCategories);
      yield put(
        Object.entries(data).map(([gender, item]) => ({
          value: gender,
          label: uppercaseFirstLetter(gender),
          children: Object.entries(item).map(([value, subCategories]) => ({
            value,
            label: uppercaseFirstLetter(value),
            children: [
              {
                value: ALL_VALUE,
                label: '全部',
              },
            ],
          })),
        }))
      );
    },
    []
  );

export const useCategoryByGenderCascaderOptions = gender =>
  useFlowEffect(
    [] as BaseOptionType[],
    function* ({ put, call }) {
      const { data }: CallNext<GetCategories> = yield call(apiService.getCategories);
      const gender_item = gender == 'women' ? data['women'] : data['men'];
      yield put(
        Object.entries(gender_item).map(([value, subCategories]) => ({
          value,
          label: uppercaseFirstLetter(value),
          children: [
            ...subCategories.map(subValue => ({
              value: subValue,
              label: uppercaseFirstLetter(subValue),
            })),
          ],
        }))
      );
    },
    []
  );
