import React, { FC, useMemo, useEffect } from 'react';
import { Alert, message } from 'antd';
import { CallNext, GetNext, useFlow, useUrlQueryState } from '@@/react-hooks';
import { ObjectUtils, ScheduleUtils } from '@@/utils';
import { SimilarProduct } from '@/interface/label';
import * as apiService from '@/service';
import { PureAntdLoading } from '@/ui/pure-antd-loading';
import { useAccountLabelInfo } from '@/store/account-label-info';
import {
  Action,
  ClassificationLabelingState,
  ItemClassificationResult,
  ValidatedItemClassificationResult,
} from './interface';
import { ClassifyLabelerCore } from './classify-labeler-core';
import { LabelUrlQuery } from '@/interface/url';
import { ALL_VALUE } from '@/const';
import { record_work } from '../../index';
import { LabelType } from '@/enum';
import { NoMoreLabeling } from '../no-more-labeling';
/**
 * run a combine process to remove combined spu
 */
const runCombine = (prev: ClassificationLabelingState): ClassificationLabelingState => {
  const { products, results, combineDict } = prev;
  const nextProducts = products.map(item =>
    ObjectUtils.immutableSet(item, ['similarProducts'], (spus: SimilarProduct[]) =>
      spus.filter(({ id }) => !combineDict[id])
    )
  );
  const nextResults = results.map(item =>
    ObjectUtils.immutableSet(item, ['classifySpuId'], (prev: number) => combineDict[prev] || prev)
  );
  return {
    ...prev,
    products: nextProducts,
    results: nextResults,
  };
};

/**
 * 校验单个结果
 * @param result
 * @returns
 */
const validateResult = (result: ItemClassificationResult) => {
  if (result.classifySpuId === undefined) {
    return false;
  }
  return true;
};

/**
 * 标注组件
 */
export const ClassifyLabeler: FC = () => {
  const [{ gender, c1, c2 }] = useUrlQueryState();

  const [, setAccountLabelInfo] = useAccountLabelInfo();

  const [{ retailer, brand }] = useUrlQueryState<LabelUrlQuery>();

  // prefetchable fetch classifier label data
  const prefetchable = useMemo(
    () =>
      ScheduleUtils.createPrefetchable(async () => {
        const res = await apiService.getClassifierLabelData({
          gender,
          category1: c1,
          category2: c2 === ALL_VALUE ? undefined : c2,
          size: 5,
          retailer: retailer || retailer != '' ? retailer?.trim() : undefined,
          brand: brand || brand != '' ? brand : undefined,
        });
        setAccountLabelInfo(res.data.labelerInfo); // immediate effect
        return res;
      }),
    [gender, c1, c2, retailer]
  );

  // 当前需要标注的批次的数据状态
  const [{ data: labelingState, loading, ready, error }, dispatchAction] = useFlow<ClassificationLabelingState, Action>(
    {
      products: [],
      results: [],
      index: 0,
      combineDict: {},
      autoNext: true, // deprecated: always true
    },
    function* ({ put, call, get }, action) {
      switch (action.type) {
        // 提交 & 拉取的流程
        case 'submit': {
          const { results, products }: GetNext<ClassificationLabelingState> = yield get();
          // validate
          if (results.some(({ isValid }) => !isValid)) {
            message.warning('请正确标注所有商品后再提交');
            return;
          }

          if (results.length) {
            // submit
            const classifyResultParams = results.map(({ classifySpuId }, index) => {
              const { labelId } = products[index];

              return {
                labelId,
                spuId: classifySpuId,
              };
            });

            // parallel submission
            // won't wait for this submission
            record_work(LabelType.Classify, JSON.stringify(classifyResultParams), gender, c1, c2);
            apiService.postClassifierLabelData(classifyResultParams).catch(e => {
              message.error(e?.msg || e?.message || '提交错误');
            });
          }

          // fetch
          const {
            data: { labelData },
          }: CallNext<typeof prefetchable> = yield call(prefetchable);

          const nextProducts = labelData.map(({ similarProducts, ...rest }) => ({
            ...rest,
            similarProducts: similarProducts.slice(0, 5), // limit count to five
          }));

          yield put(prev => {
            const next = runCombine({
              ...prev,
              products: nextProducts,
              results: Array<ValidatedItemClassificationResult>(nextProducts.length).fill({
                classifySpuId: undefined,
                isValid: false,
              }),
              index: 0,
            }); // run combine for the cached data
            return {
              ...next,
              combineDict: {}, // clear combineDict
            };
          });

          break;
        }

        // 修改标注内容
        case 'label': {
          yield put(prev => {
            const { results, index } = prev;
            const nextResult = {
              ...results[index],
              ...action.payload,
            };
            // 实时校验
            const isValid = validateResult(nextResult);
            nextResult.isValid = isValid;
            // 当前的完成了，且下一个没有完成，可以自动翻到下一个
            const nextIndex = index < results.length - 1 && isValid && !results[index + 1].isValid ? index + 1 : index;
            return {
              ...prev,
              index: nextIndex,
              results: ObjectUtils.immutableSet(results, [index], nextResult),
            };
          });
          break;
        }

        // 合并
        case 'combine': {
          const { toDelete, toKeep, prioritySpuId } = action.payload;
          const to_delete = toKeep == prioritySpuId ? toDelete : toKeep;
          const to_keep = toKeep == prioritySpuId ? toKeep : toDelete;
          yield put(prev => {
            const next = ObjectUtils.immutableSet(prev, ['combineDict'], (prev: Record<number, number>) => ({
              ...prev,
              [to_delete]: to_keep,
            }));
            return runCombine(next);
          });
          break;
        }

        // 修改商品信息
        case 'update': {
          const { imagesOrders, crawlerProductId, changeCategory1, changeCategory2 } = action.payload;
          apiService
            .putCrawlerInfo({
              crawlerProductId: crawlerProductId,
              images: imagesOrders.length != 0 ? imagesOrders : undefined,
              category1: changeCategory1 ? changeCategory1 : undefined,
              category2: changeCategory2 ? changeCategory2 : undefined,
            })
            .catch(e => {
              message.error(e?.msg || e?.message || '修改错误，请检查分类或图片');
            });
          yield put(prev => {
            const { results, index, products } = prev;
            results.splice(index, 1);
            products.splice(index, 1);
            return {
              ...prev,
              index: results[index] ? index : index - 1,
              results: results,
            };
          });
          break;
        }

        // 翻页
        case 'to':
          yield put(prev => ({
            ...prev,
            index: action.payload,
          }));
          break;
        // 切换自动下一个
        case 'switch-auto':
          yield put(prev => ({
            ...prev,
            autoNext: !prev.autoNext,
          }));
          break;
        default:
          break;
      }
    }
  );

  useEffect(() => {
    dispatchAction({
      type: 'submit',
    });
    sessionStorage.setItem('Tabs_number', '1');
    sessionStorage.setItem('record_time', '0');
    sessionStorage.setItem('listener', '0');
  }, []);

  if (loading) {
    return <PureAntdLoading size="large" tip="正在获取标注数据..." />;
  }

  if (error) {
    console.log('error', error);
    const errorDescription = error?.msg || error?.data || '未知错误';
    return <Alert message="获取标注数据错误" showIcon description={errorDescription} type="error" />;
  }

  if (!ready) {
    return null;
  }
  if (labelingState.products.length == 0) {
    return <NoMoreLabeling />;
  }

  return <ClassifyLabelerCore key={labelingState.index} data={labelingState} dispatch={dispatchAction} />;
};
