import { ApiResponseCode } from '@/enum';
import { AccountInfo } from '@/interface/account';
import { ValueType } from '@@/util-types';
import { useFlow } from '@@/react-hooks';
import { message } from 'antd';
import { useEffect } from 'react';
import * as apiService from '@/service';
import { createModel } from '.';

export const accountActions = {
  query() {
    return {
      type: 'query' as const,
    };
  },
};

export type AccountAction = ReturnType<ValueType<typeof accountActions>>;

export const useAccountState = createModel(() => {
  const [accountState, dispatch] = useFlow<AccountInfo, AccountAction>(
    null,
    function* ({ put, call }, action) {
      switch (action.type) {
        case 'query': {
          try {
            const { data } = yield call(apiService.getUserInfo);
            yield put(data);
          } catch (e) {
            if (e?.code !== ApiResponseCode.Forbidden) {
              message.error(e?.msg || '服务错误');
            }
          }
          break;
        }
        default:
          break;
      }
    }
  );

  useEffect(() => {
    dispatch(accountActions.query());
  }, []);

  return [accountState, dispatch] as const;
});

export const useAccountInfo = () => {
  const [{ data: accountInfo }] = useAccountState();

  return accountInfo;
};
