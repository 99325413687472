import React, { FC, memo } from "react";
import { Empty } from "antd";
import classNames from "classnames";
import { useHistory } from "react-router";
import { url } from "@/url";

import commonStyles from '../../common.module.less';

export const NoMoreLabeling: FC = memo(() => {
  const history = useHistory();

  const description = (
    <>
      没有可标注的商品了，
      <a onClick={() => history.push(url.home())}>返回首页</a>
    </>
  )
  return (
    <Empty
      className={classNames(commonStyles.single_column, commonStyles.empty)}
      description={description}
    />
  )
})