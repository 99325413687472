import { createContext } from 'react';
import { Gender } from '@/enum';
import { FlowObject } from '@@/react-hooks';
import { BaseOptionType } from 'antd/lib/cascader';
import { FormItemProps } from 'antd';

export interface HomeContext {
  commonFormItemProps: FormItemProps;
  categoryCascaderOptionsState: FlowObject<BaseOptionType[]>;
  genderDesignerOptionsDictState: FlowObject<Record<Gender, string[]>>;
  genderRetailersOptionsDictState: FlowObject<string[]>;
  labelerListState: FlowObject<string[]>;
}

export const homeContext = createContext<HomeContext>(null);
