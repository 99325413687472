import React, { useRef } from "react";
import { useDrop, useDrag } from "react-dnd";


export default (props: any) => {
  const {
    id = "",
    index = "",
    changePosition = () => { },
    children,
    rowKey,
    className,
    ischange,
  } = props;

  const ref: any = useRef(null);

  const [{ isOver }, drop] = useDrop({
    accept: "DragDropImg",
    hover: (item: any, monitor: any) => {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;
      changePosition(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
  });

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "DragDropImg",
    item: { id, type: "DragDropImg", index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
  }));

  const changeRef = drag(drop(ref));
  return (
    ischange ?
      <div
        //@ts-ignore
        ref={changeRef}
        style={{ opacity: isDragging ? 0.5 : 1, borderRight: !isDragging && isOver ? "3px solid grey" : '' }}
        className="dragImg"
      >
        <span key={rowKey} className={className}>
          {children}
        </span>
      </div> :
      <div>
        <span key={rowKey} className={className}>
          {children}
        </span>
      </div>
  );
};