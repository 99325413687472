/**
 * 路由层
 */

import React from 'react';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { url } from '@/url';
import { Home } from '@/view/home';
import { Label } from '@/view/label';
import { ReportView } from './view/report';
import { ReportDetailsView } from './view/report/label-details';
import { ReportCategoryView } from './view/report/label-category';
import { ReportOnlineView } from './view/report/label-online';
import { ReportRrefreshView } from './view/report/label-refresh';

// 路由工厂函数
interface RouteFactory {
  (routenHelper: {
    params: Record<string, any>; // URL 匹配参数（不包括 ? 后的查询参数）
  }): JSX.Element;
}

const routeConfig: [string, RouteFactory][] = [
  // 主页
  [url.home(), () => <Home />],
  // 标注页入口
  [
    url.label(':type'),
    ({ params: { type } }) => <Label type={type} />,
  ],
  [url.report(), () => <ReportView />],
  [url.details(), () => <ReportDetailsView />],
  [url.category(), () => <ReportCategoryView />],
  [url.online(), () => <ReportOnlineView />],
  [url.refresh(), () => <ReportRrefreshView />],
];

export const AppRoutes = () => {
  const routes = routeConfig.map(([path, factory]) => {
    return (
      <Route
        key={path}
        path={path}
        exact
        render={({ match: { params } }) => {
          return factory({ params });
        }}
      />
    );
  });

  return (
    <Router>
      <Switch>
        {routes}
        <Redirect to={routeConfig[0][0]} />
      </Switch>
    </Router>
  );
};
