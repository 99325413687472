import React, { FC, useCallback, useContext, useRef, useEffect } from 'react';
import { Form, Cascader, Button, Select, FormInstance } from 'antd';
import { LabelType, Gender } from '@/enum';
import { url } from '@/url';
import { useHistory } from 'react-router';
import { homeContext } from './context';
import { ClassifyFormValues } from './interface';
import styles from './home.module.less';
import { useFlow, CallNext } from '@@/react-hooks';
import { BaseOptionType } from 'antd/lib/cascader';
import * as apiService from '@/service';
import { GetDesigners } from '@/service/interface';

export const ClassifyForm: FC = () => {
  const {
    commonFormItemProps,
    categoryCascaderOptionsState,
    genderRetailersOptionsDictState,
    genderDesignerOptionsDictState,
  } = useContext(homeContext);
  const category = sessionStorage.getItem('classify_category')
    ? sessionStorage.getItem('classify_category').split(',')
    : '';
  const retailer = sessionStorage.getItem('classify_retailer')
    ? sessionStorage.getItem('classify_retailer')
    : undefined;
  const brand = sessionStorage.getItem('classify_brand') ? sessionStorage.getItem('classify_brand') : undefined;

  const history = useHistory();

  const [designerOptionsState, dispatch] = useFlow(
    [] as {
      brand: string;
    }[],
    function* ({ call, put, cancel }, category: BaseOptionType) {
      if (!category) return;
      yield cancel();
      yield put([]);
      const [gender, category1, category2] = category as [Gender, string, string];
      const { data }: CallNext<GetDesigners> = yield call(apiService.getDesigners);
      yield put(
        (data[gender] || []).map(brand => ({
          brand,
        }))
      );
    }
  );

  const formRef = useRef<FormInstance>();

  useEffect(() => {
    dispatch(formRef.current.getFieldsValue().category);
  }, []);

  const onValuesChange = useCallback(changes => {
    if (changes.category) {
      dispatch(changes.category);
    }
    const form = formRef.current;
    const { category, brand } = form.getFieldsValue();
    const gender: Gender = category?.[0];
    if (gender && brand && !genderDesignerOptionsDictState.data[gender].includes(brand)) {
      form.setFieldsValue({
        brand: brand,
      });
    }
  }, []);

  const onStartClassify = useCallback(
    ({ category: [gender, c1, c2], retailer, brand }: ClassifyFormValues) => {
      history.push(url.label(LabelType.Classify, { gender, c1, c2, retailer, brand }));
    },
    [history]
  );
  return (
    <Form<ClassifyFormValues>
      ref={formRef}
      className={styles.form}
      size="large"
      onFinish={onStartClassify}
      onValuesChange={onValuesChange}
      initialValues={{
        category: category ? category : undefined,
        retailer: retailer != 'null' && retailer != 'undefined' ? retailer : '',
        brand: brand != 'null' && brand != 'undefined' ? brand : undefined,
      }}
    >
      <Form.Item
        {...commonFormItemProps}
        label="类别"
        name="category"
        required
        rules={[{ required: true, message: '请选择类别' }]}
      >
        <Cascader placeholder="请选择商品类别" options={categoryCascaderOptionsState.data} />
      </Form.Item>
      <Form.Item {...commonFormItemProps} label="零售商" name="retailer">
        <Select placeholder="请选择需要指定的零售商" showSearch loading={genderRetailersOptionsDictState.loading}>
          <Select.Option value="">全部</Select.Option>
          {genderRetailersOptionsDictState.data.map(labeler => (
            <Select.Option key={labeler} value={labeler}>
              {labeler}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="品牌" name="brand" {...commonFormItemProps}>
        <Select
          optionLabelProp="value"
          loading={designerOptionsState.loading}
          showSearch
          filterOption={true}
          placeholder="选择需要指定审核的品牌名称"
          allowClear
        >
          <Select.Option value="">全部</Select.Option>
          {designerOptionsState.data.map(({ brand }) => (
            <Select.Option key={brand} value={brand}>
              <div className={styles.designer_option}>
                <span>{brand}</span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Button htmlType="submit" type="primary" size="large" className={styles.button}>
        开始标注
      </Button>
    </Form>
  );
};
