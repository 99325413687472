import { useSuperState } from '@@/react-hooks';
import { AccountLabelInfo } from '@/interface/account';
import { createModel } from '.';


export const useAccountLabelInfo = createModel(() => useSuperState<AccountLabelInfo>({
  verifiedCount: 0,
  verifier: '',
  labeledCount: 0,
  labeler: '',
  pendingLabel: 0,
  pendingVerify: 0,
}));
