import React, { FC, CSSProperties } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import styles from './index.module.less';

export interface PureAntdLoadingProps {
  className?: string;
  style?: CSSProperties;
  size?: 'small' | 'default' | 'large';
  tip?: string;
}

const sizeFontDict: Record<PureAntdLoadingProps['size'], number> = {
  small: 16,
  default: 24,
  large: 32,
}

export const PureAntdLoading: FC<PureAntdLoadingProps> = ({ size, tip, className, style = {} }) => (
  <div
    className={classNames(className || '', styles.pure_antd_loading)}
    style={style} >
    <Spin
      indicator={
        <LoadingOutlined style={{ fontSize: sizeFontDict[size] }} spin />
      }
      tip={tip}
    />
  </div>
);
