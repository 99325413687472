import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Form, DatePicker, Button, Spin, Select, Table } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Moment } from 'moment';
import { CallNext, useFlow, useFlowEffect } from '@@/react-hooks';
import * as apiService from '@/service';
import { useAccountInfo } from '@/store/account';
import { UserRole } from '@/enum';
import { url } from '@/url';
import { ExportLabelCategory } from '@/service/interface';
import { GetLabelerList } from '@/service/interface';

import styles from './report.module.less';

interface FormValues {
  range: [Moment, Moment];
  labeler: string;
}

export const ReportCategoryView: FC = () => {
  const account = useAccountInfo();

  const labelerListState = useFlowEffect([] as string[], function* ({ put, call }) {
    const { data }: CallNext<GetLabelerList> = yield call(apiService.getLabelerList);
    yield put(data);
  }, []);

  const [{ data, loading }, onOk] = useFlow('', function* ({ put, call }, { range, labeler }: FormValues) {
    const [start, end] = range;
    const { data }: CallNext<ExportLabelCategory> = yield call(apiService.exportLabelCategory, {
      start_date: start.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      end_date: end.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      labeler: labeler,
    });
    yield put(JSON.stringify(data));
  });

  if (account.userStatus === UserRole.Annotator) {
    return <Redirect to={url.home()} />;
  }

  const columns = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '次数',
      dataIndex: 'label_count',
      key: 'label_count',
    },
    {
      title: 'gender',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'category1',
      dataIndex: 'category1',
      key: 'category1',
    },
    {
      title: 'category2',
      dataIndex: 'category2',
      key: 'category2',
    },
  ];

  return (
    <div className={styles.report}>
      <Form preserve={false} className={styles.form} onFinish={onOk}>
        <Link className={styles.back_link} to={url.home()}>
          <LeftOutlined /> 返回首页
        </Link>
        <Form.Item required rules={[{ required: true, message: '请先选择日期范围' }]} name="range">
          <DatePicker.RangePicker placeholder={['开始日期', '结束日期']} />
        </Form.Item>
        <span style={{ 'width': '20px' }}></span>
        <Form.Item label="账号" name="labeler" >
          <Select placeholder="选择标注账号" loading={labelerListState.loading} allowClear>
            {labelerListState.data.map(labeler => (
              <Select.Option key={labeler} value={labeler}>
                {labeler}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <footer>
          <Button htmlType="submit" type="primary" className={styles.button}>
            点击查询
          </Button>
        </footer>
      </Form>
      <Spin spinning={loading}>
        <div className={styles.content}>
          <Table dataSource={data ? JSON.parse(data) : []} columns={columns} rowKey={()=>Math.random()}/>;
        </div>
      </Spin>
      <div />
    </div>
  );
};