import React, { FC, MouseEvent } from 'react';
import { Button, ButtonProps } from 'antd';
import { useState } from 'react';
import { useCallback } from 'react';

export interface LoadableAntdButtonProps extends Omit<ButtonProps, 'loading'> {
  onClick(e: MouseEvent): void | Promise<void>;
}

export const LoadableAntdButton: FC<LoadableAntdButtonProps> = props => {
  const [loading, setLoading] = useState(false);

  const onClick = useCallback(
    (e: MouseEvent) => {
      const result = props.onClick(e);
      if (result instanceof Promise) {
        setLoading(true);
        result.finally(() => {
          setLoading(false);
        });
      }
    },
    [props.onClick]
  );

  return <Button {...props} onClick={onClick} loading={loading} />;
};


export interface LoadableChangeAntdButtonProps extends Omit<ButtonProps, 'loading'> {
  onClick(e: MouseEvent): void | Promise<void>;
  onChange?(): void;
}

export const LoadableChangeAntdButton: FC<LoadableChangeAntdButtonProps> = props => {
  const [loading, setLoading] = useState(false);

  const onClick = useCallback(
    (e: MouseEvent) => {
      const result = props.onClick(e);
      if (result instanceof Promise) {
        setLoading(true);
        result.finally(() => {
          setLoading(false);
          props.onChange()
        });
      }
    },
    [props.onClick, props.onChange]
  );

  return <Button {...props} onClick={onClick} loading={loading} />;
};
