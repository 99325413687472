import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { Modal, Radio, Select } from 'antd';
import { Action, ClassificationLabelingState } from './interface';
import { LabelLayout, LabelLayoutHeader } from '../layout';
import { LoadableAntdButton } from '@/ui/loadable-antd-button';
import { noop } from '@@/utils';
import { CombineProvider, ItemSelect, SpuSelectableItem } from '../item-select';
import { SimilarProduct } from '@/interface/label';
import { useModal } from '@@/react-hooks';
import { SpuDisplay } from '../spu-display';
import { NoMoreLabeling } from '../no-more-labeling';
import { Gallery } from '@/ui/gallery';
import addImage from './add.png';
import commonStyles from '../../common.module.less';
import styles from './classify-labeler-core.module.less';
import { LabelType, LabelSourceEnum } from '@/enum';
import { useSuperState } from '@@/react-hooks';
import { useCategoryByGenderCascaderOptions } from '@/hooks/use-category-cascader-options';

const EMPTY_SPU_FOR_LABEL: SimilarProduct = {
  brand: '',
  category1: '',
  category2: '',
  color: '',
  composition: '',
  currency: '',
  description: '',
  gender: null,
  id: null,
  image: addImage,
  images: [],
  altImages: [],
  name: '新商品',
  price: 0,
  retailer: '',
  isOfficial: false,
  spuStatus: null,
  thumbnail: '',
  collabDesigner: [],
  styleId: '',
  normalizedStyleId: '',
  imgUrl: '',
};

interface ClassifySubmission {
  imagesOrders: string[];
  changeCategory1: string;
  changeCategory2: string;
}

/**
 * 核心标注组件
 */
export const ClassifyLabelerCore: FC<{
  data: ClassificationLabelingState;
  dispatch(action: Action): void;
}> = ({ data, dispatch }) => {
  const { results, products, index } = data;
  const [zoomModalState, openZoomModal, closeZoomModal] = useModal<SimilarProduct>(null);
  const [category1State, opencategory1Modal, closecategory1Modal] = useModal<{}>(null);
  const [category2State, opencategory2Modal, closecategory2Modal] = useModal<{}>(null);
  const [labelState, setLabelState] = useSuperState<ClassifySubmission>({
    imagesOrders: [],
    changeCategory1: '',
    changeCategory2: '',
  });
  // initialize labeling state while spu mutated
  useEffect(() => {
    setLabelState({
      imagesOrders: [],
      changeCategory1: '',
      changeCategory2: '',
    });
    closecategory1Modal();
    closecategory2Modal();
  }, [products[index].labelId]);

  const contentNode = (() => {
    const currentItem = products[index];

    if (!currentItem) {
      return <NoMoreLabeling />;
    }

    const { classifySpuId } = results[index];
    const { category1, category2, gender } = currentItem;
    sessionStorage.setItem('styleId', currentItem.normalizedStyleId);
    const categorysCascaderOptionsState = useCategoryByGenderCascaderOptions(gender);
    let category2CascaderOptionsState = [];
    const category1CascaderOptionsState = [];
    for (const options of categorysCascaderOptionsState.data) {
      category1CascaderOptionsState.push({ value: options['value'], label: options['label'] });
      const c1 = labelState.changeCategory1 ? labelState.changeCategory1 : category1;
      if (options['value'] == c1) {
        category2CascaderOptionsState = options['children'];
      }
    }
    const onChangeCategory1 = (changeCategory1: string) => {
      setLabelState(prev => ({
        ...prev,
        changeCategory1,
      }));
    };
    const onChangeCategory2 = (changeCategory2: string) => {
      setLabelState(prev => ({
        ...prev,
        changeCategory2,
      }));
    };
    const closeChangeCategory = () => {
      closecategory1Modal();
      onChangeCategory1('');
      closecategory2Modal();
      onChangeCategory2('');
    };

    const onChangeImagesOrders = (imagesOrders: string[]) => {
      setLabelState(prev => ({
        ...prev,
        imagesOrders,
      }));
    };

    return (
      <>
        <div className={commonStyles.left_of_2}>
          <div className={commonStyles.vertical_container}>
            <header className={commonStyles.header}>
              <h3 className={commonStyles.title} onClick={closeChangeCategory}>
                当前商品
              </h3>
              <span className={commonStyles.header_addon}>
                {gender} /{' '}
                {category1State.visible ? (
                  <Select
                    placeholder="请选择修改的类别"
                    onChange={onChangeCategory1}
                    allowClear
                    onClear={closecategory1Modal}
                  >
                    {category1CascaderOptionsState.map(data => (
                      <Select.Option key={data.label} value={data.value}>
                        {data.value}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <span onClick={opencategory1Modal} style={{ border: '1px' }}>
                    {category1}{' '}
                  </span>
                )}
                /{' '}
                {category2State.visible ? (
                  <Select
                    placeholder="请选择修改的类别"
                    onChange={onChangeCategory2}
                    allowClear
                    onClear={closecategory2Modal}
                  >
                    {category2CascaderOptionsState.map(data => (
                      <Select.Option key={data.label} value={data.value}>
                        {data.value}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <span onClick={opencategory2Modal} style={{ border: '1px' }}>
                    {category2}{' '}
                  </span>
                )}
              </span>
            </header>
            <SpuDisplay data={currentItem} onChangeImagesOrders={onChangeImagesOrders} />
          </div>
        </div>
        <div className={commonStyles.right_of_2}>
          <div className={commonStyles.vertical_container}>
            <header className={commonStyles.header}>
              <h3 className={commonStyles.title}>选择左侧商品应分入的商品品类，拖拽以合并相同的商品</h3>
            </header>
            <ItemSelect cols={3}>
              <CombineProvider
                source={LabelSourceEnum.CLASSIFY_DRAG}
                onCombineFinish={data =>
                  dispatch({
                    type: 'combine',
                    payload: data,
                  })
                }
              >
                {[EMPTY_SPU_FOR_LABEL, ...currentItem.similarProducts].map(data => {
                  const checked = data.id === classifySpuId;
                  return (
                    <SpuSelectableItem
                      key={data.id}
                      data={data}
                      checked={checked}
                      onClick={
                        checked
                          ? noop
                          : () =>
                              dispatch({
                                type: 'label',
                                payload: {
                                  classifySpuId: data.id,
                                },
                              })
                      }
                      onZoom={() => openZoomModal(data)}
                    >
                      <Radio className={commonStyles.checker} checked={checked} />
                    </SpuSelectableItem>
                  );
                })}
              </CombineProvider>
            </ItemSelect>
          </div>
        </div>
      </>
    );
  })();

  return (
    <LabelLayout>
      <LabelLayoutHeader type={LabelType.Classify}>
        {labelState.changeCategory1 ||
        labelState.changeCategory2 ||
        (labelState.imagesOrders.length != 0 && products[index].images.includes(labelState.imagesOrders[0])) ? (
          <LoadableAntdButton
            style={{ marginRight: '15px' }}
            onClick={() =>
              dispatch({
                type: 'update',
                payload: {
                  imagesOrders: labelState.imagesOrders,
                  changeCategory1: labelState.changeCategory1 || products[index].category1,
                  changeCategory2: labelState.changeCategory2 || products[index].category2,
                  crawlerProductId: products[index].crawlerProductId,
                },
              })
            }
          >
            信息修改
          </LoadableAntdButton>
        ) : null}
        <div className={styles.indexes}>
          {results.map(({ isValid }, i) => {
            const selected = i === index;
            const onClick = selected
              ? noop
              : () =>
                  dispatch({
                    type: 'to',
                    payload: i,
                  });
            return (
              <div
                className={classNames(styles.index, {
                  [styles['index--selected']]: selected,
                })}
                onClick={onClick}
                key={i}
              >
                {i + 1}
                {isValid ? <div className={styles.valid_badge} /> : null}
              </div>
            );
          })}
        </div>
        <LoadableAntdButton
          onClick={() =>
            dispatch({
              type: 'submit',
            })
          }
        >
          提交该批次
        </LoadableAntdButton>
      </LabelLayoutHeader>

      {contentNode}

      <Modal
        open={zoomModalState.visible}
        onCancel={closeZoomModal}
        title={null}
        footer={null}
        width="50vw"
        mask={false}
        maskClosable={false}
        style={{ left: '50vw', margin: 0 }}
      >
        {zoomModalState.data && <Gallery data={zoomModalState.data.images} />}
      </Modal>
    </LabelLayout>
  );
};
