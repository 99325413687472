export * from '@reversible/schema';

// 性别
export enum UserRole {
  Test = 0,
  Annotator = 1,
  Admin = 2,
}

//label source
export enum LabelSourceEnum {
  CLASSIFY_DRAG = 'classify-drag',
  VERIFY_DRAG = 'verify-drag',
  VERIFY_SELECT = 'verify-select',
  KEYWORD_SEARCH = 'keyword-search',
  STYLEID_DRAG = 'styleId-drag'
}

// type of labeling
export enum LabelType {
  Classify = 'classify',
  Verify = 'verify',
  Combine = 'combine',
  StyleId = 'styleId'
}

export enum SortKey {
  MostRelevant = 7,
  Featured = 0,
  LowPrice = 1,
  HighPrice = 2,
  Latest = 3,
  Popular = 4,
  NewLowestAsks = 5,
  NewHighestBids = 6,
  SaleHighPrice = 8,
  Top = 9,
  LowOfferPrice = 10,
  HighOfferPrice = 11,
}

export const availableLabelTypes = (userRole: UserRole) =>
  userRole === UserRole.Annotator ? [LabelType.Classify] : [LabelType.Classify, LabelType.Verify, LabelType.Combine, LabelType.StyleId];
