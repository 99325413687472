import { Button, Form, Input, message } from 'antd';
import { identity } from '@@/utils';
import React, { FC, useCallback, useContext } from 'react';
import { homeContext } from './context';
import { SpecifiedFormValues } from './interface';
import styles from './home.module.less';
import { useHistory } from 'react-router-dom';
import { url } from '@/url';
import { LabelType } from '@/enum';

export const SpecifiedVerifyForm: FC = () => {
  const { commonFormItemProps } = useContext(homeContext);

  const history = useHistory();

  const onStartVerify = useCallback(({ spuIds: raw }: SpecifiedFormValues) => {
    const spuIds = raw
      .split('\n')
      .map(row => row.trim())
      .filter(identity)
      .map(Number)
      .filter(value => !Number.isNaN(value));
    if (!spuIds.length) {
      message.warning('请至少输入一个合法的 Spu ID');
      return;
    }
    history.push(url.label(LabelType.Verify, { spuIds }));
  }, []);

  return (
    <Form<SpecifiedFormValues>
      initialValues={{
        spuIds: '',
      }}
      size="large"
      onFinish={onStartVerify}
    >
      <Form.Item {...commonFormItemProps} label="SPU ID" name="spuIds" required>
        <Input.TextArea placeholder="输入需要审核的 SPU ID，每行输入一个，可输入多个" rows={5} />
      </Form.Item>
      <Button htmlType="submit" type="primary" size="large" className={styles.button}>
        开始审核
      </Button>
    </Form>
  );
};
