import React, { FC, Children, createContext, useCallback, useContext, useState, ReactNode } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { webUrl } from '@/url';
import { Img } from '@/ui/img';
import { Empty, message, Modal } from 'antd';
import { StarFilled, ZoomInOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { stop } from '@@/utils';
import { useModal } from '@@/react-hooks';
import { OfferForVerification, SimilarProduct, SpuForVerification } from '@/interface/label';
import { SpuCombineResult } from '@/interface/label';
import { DraggableTypes } from '@/const';
import * as apiService from '@/service';
import styles from './index.module.less';
import commonStyles from '../../common.module.less';
import { LabelSourceEnum } from '@/enum';
import { url } from '@/url';
import { Gender, LabelType } from '@/enum';
import { useHistory } from 'react-router-dom';
import { useUrlQueryState } from '@@/react-hooks';
import { LabelUrlQuery } from '@/interface/url';
import { createFromIconfontCN } from '@ant-design/icons';
import { Popover } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { record_work, getCurrentTime, getTimeAgo } from '../../index';

const awaitCombineConfirm = msg =>
  new Promise<boolean>((resolve, reject) => {
    let comfirm_msg = '商品非同一个品牌，是否强制合并?';
    if (msg == 'Both spu has user interactions') {
      comfirm_msg = '商品存在用户行为，是否强制合并?';
    }
    Modal.confirm({
      title: comfirm_msg,
      icon: <ExclamationCircleFilled />,
      content: '',
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    });
  });

const truncateText = ({ text, maxLength = 100 }) => {
  if (text == null) {
    return;
  }
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.substring(0, maxLength)}...`;
};

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3910887_nc6i6ddint9.js',
});

/**
 * spu 选择器
 * @param param0
 * @returns
 */
export const ItemSelect: FC<{
  children?: ReactNode;
  cols: number;
}> = ({ children, cols }) =>
  Children.count(children) ? (
    <ul
      className={styles.item_select}
      style={{
        gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`,
      }}
    >
      {children}
    </ul>
  ) : (
    <Empty className={commonStyles.empty} description={children || '没有可选项目'} />
  );

const combineContext = createContext<(spu0: SimilarProduct, spu1: SimilarProduct) => void>(null);

interface SelectableItemProps<T> {
  data: T;
  checked?: boolean;
  children?: ReactNode;
  onClick?(): void;
  onZoom?(): void;
}

const findCommonStartsWith = (str1, str2) => {
  str1 = str1 ? str1.toLowerCase() : '';
  str2 = str2 ? str2.toLowerCase() : '';
  const dp = Array.from({ length: str1.length + 1 }, () => Array(str2.length + 1).fill(0));
  let maxLength = 0;
  let endIndex = 0;
  for (let i = 1; i <= str1.length; i++) {
    for (let j = 1; j <= str2.length; j++) {
      if (str1[i - 1] === str2[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1] + 1;
        if (dp[i][j] > maxLength) {
          maxLength = dp[i][j];
          endIndex = i - 1;
        }
      }
    }
  }
  const commonStr = str1.substring(endIndex - maxLength + 1, endIndex + 1);
  if (commonStr.length >= 5) {
    return commonStr;
  }
  return '';
};

const HighlightText = ({ children, id_obj, highlight }: any) => {
  if (highlight) {
    const parts = children.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <mark key={index}>
              {part}
              {id_obj}
            </mark>
          ) : (
            <span key={Math.random()}>
              {part}
              {id_obj}
            </span>
          )
        )}
      </span>
    );
  } else {
    return (
      <span>
        {children}
        {id_obj}
      </span>
    );
  }
};

export const SpuSelectableItem: FC<SelectableItemProps<SimilarProduct>> = ({
  data,
  checked = false,
  onClick,
  onZoom,
  children,
}) => {
  const { name, image, imgUrl, id, currency, price, color, altImages, images, isOfficial, brand, collabDesigner } =
    data;
  const onCombine = useContext(combineContext);
  const draggable = Boolean(onCombine && data.id);
  const style_id = sessionStorage.getItem('styleId');
  const commonStart = findCommonStartsWith(style_id, truncateText({ text: data.normalizedStyleId, maxLength: 20 }));
  const [{ isDragging }, dragRef, dragPreviewRef] = useDrag(
    {
      type: DraggableTypes.SPU_ITEM,
      item: data,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: draggable,
    },
    [data, draggable]
  );

  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: DraggableTypes.SPU_ITEM,
      collect: monitor => ({
        isOver: monitor.isOver(),
      }),
      canDrop: (data: SimilarProduct) => draggable && id !== data.id,
      drop: (spuDropped: SimilarProduct) => onCombine(data, spuDropped),
    }),
    [onCombine, draggable, data]
  );

  const [query_datas] = useUrlQueryState<LabelUrlQuery>();
  let [query_gender, query_category1, query_category2] = [query_datas.gender, query_datas.c1, query_datas.c2] as [
    Gender,
    string,
    string
  ];
  let query_brand = query_datas.brand;
  const history = useHistory();
  const current_tabs = history['location']['pathname'];

  const brands = [brand];
  if (collabDesigner) {
    for (const item of collabDesigner) {
      if (item != brand) {
        brands.push(item);
      }
    }
  }
  const collab_designers = collabDesigner ? brands.join(' x ') : brand;
  const altImage = altImages?.[0] || images?.[1];
  const spuIds = [id];
  const [keyword, setKeyword] = useState('');
  const [keywordUrl, setKeywordUrl] = useState('');
  const [keywordTime, setKeywordTime] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);
  const click_keyword = (id, action = 'name') => {
    const selectedKeyword = window.getSelection().toString();
    if (!selectedKeyword) return;
    const node_id = window.getSelection().anchorNode.nextSibling
      ? window.getSelection().anchorNode.nextSibling.textContent
      : null;
    if (id != node_id) return;
    setKeyword(selectedKeyword);

    // 设置关键字上一次使用时间
    query_gender = query_gender ? query_gender : data.gender;
    query_category1 = query_category1 ? query_category1 : data.category1;
    query_category2 = query_category2 ? query_category2 : data.category2;
    query_brand = query_brand ? query_brand : data.brand;
    if (current_tabs.includes('verify')) {
      query_category2 = 'all';
    }
    const timeoutKey = [];
    const keyName = [query_gender, query_brand, query_category1, query_category2, selectedKeyword?.toLowerCase()];
    keyName.filter(data => (data ? timeoutKey.push(data) : timeoutKey));
    const timeout_key = timeoutKey.join('-');
    const keywordTimeout = localStorage.getItem('keywordTime') ? JSON.parse(localStorage.getItem('keywordTime')) : {};
    if (!Object.keys(keywordTimeout).includes(timeout_key)) {
      keywordTimeout[timeout_key] = getCurrentTime();
      localStorage.setItem('keywordTime', JSON.stringify(keywordTimeout));
    }
    const timeAgo = getTimeAgo(Date.parse(keywordTimeout[timeout_key]));
    setKeywordTime(timeAgo);
    if (query_brand != 'undefined') {
      if (action == 'price') {
        setKeywordUrl(
          url.label(LabelType.Combine, {
            gender: query_gender,
            c1: query_category1,
            c2: query_category2,
            brand: query_brand,
            priceKeyword: selectedKeyword,
          })
        );
      } else {
        setKeywordUrl(
          url.label(LabelType.Combine, {
            gender: query_gender,
            c1: query_category1,
            c2: query_category2,
            brand: query_brand,
            keyword: selectedKeyword,
          })
        );
      }
    } else {
      if (action == 'price') {
        setKeywordUrl(
          url.label(LabelType.Combine, {
            gender: query_gender,
            c1: query_category1,
            c2: query_category2,
            priceKeyword: selectedKeyword,
          })
        );
      } else {
        setKeywordUrl(
          url.label(LabelType.Combine, {
            gender: query_gender,
            c1: query_category1,
            c2: query_category2,
            keyword: selectedKeyword,
          })
        );
      }
    }
  };

  return (
    <li
      className={classNames(styles.selectable_item, styles.selectable_item_padding, {
        [styles.selectable_item_selected]: checked,
        [styles.selectable_item_draggover]: !isDragging && draggable && isOver,
        [styles.selectable_item_dragging]: isDragging,
      })}
      ref={dropRef}
      onClick={onClick ? () => onClick() : undefined}
    >
      <div>
        <div ref={dragRef}>
          <div
            className={styles.img_wrapper}
            ref={dragPreviewRef}
            onMouseOver={() => setShowOverlay(true)}
            onMouseOut={() => setShowOverlay(false)}
          >
            {image || imgUrl ? (
              <Img className={styles.img} src={image || imgUrl} alt={name} />
            ) : (
              <Img className={styles.img} src={data.thumbnail} alt={name} />
            )}
            {altImage ? <Img className={styles.alt_img} src={altImage} alt={name} /> : null}
            {isOfficial ? <StarFilled className={styles.icon} /> : null}
            <div className={styles.verify_btn}>
              <a href={url.label(LabelType.Verify, { spuIds })} target="_blank" onClick={stop()}>
                <button>verify</button>
              </a>
            </div>
            {showOverlay && data.composition ? (
              <div className={styles.composition}>{truncateText({ text: data.composition })}</div>
            ) : null}
          </div>
        </div>
        <footer className={styles.footer}>
          {children}
          <div className={styles.info}>
            <div onClick={stop()} className={styles.name_line}>
              <span
                className={classNames(styles.name_keyword, styles.dualline)}
                onMouseMove={() => click_keyword(id)}
                onMouseUp={() => click_keyword(id)}
              >
                <Popover
                  trigger="hover"
                  content={
                    <span>
                      <a href={keyword ? keywordUrl : '#'}>
                        {keyword} <SearchOutlined />
                      </a>{' '}
                      <span> {keywordTime}</span>
                    </span>
                  }
                >
                  {name}
                  <span style={{ display: 'none' }}>{id}</span>
                </Popover>
              </span>
              <a className={styles.name_icon} target="_blank" href={webUrl.product(data)}>
                <IconFont type="icon-fenxiang" />
              </a>
            </div>
            {collab_designers ? (
              <h5 className={styles.info_item} onClick={stop()}>
                {collab_designers}
              </h5>
            ) : null}
            {data.normalizedStyleId ? (
              <h5
                className={styles.info_item}
                onMouseMove={() => click_keyword(id)}
                onClick={stop()}
                onMouseUp={() => click_keyword(id)}
              >
                <Popover
                  trigger="hover"
                  content={
                    <span>
                      <a href={keyword ? keywordUrl : '#'}>
                        {keyword} <SearchOutlined />
                      </a>
                      <span> {keywordTime}</span>
                    </span>
                  }
                >
                  <HighlightText highlight={commonStart} id_obj={<span style={{ display: 'none' }}>{id}</span>}>
                    {truncateText({ text: data.normalizedStyleId, maxLength: 20 })}
                  </HighlightText>
                  <span style={{ display: 'none' }}>{id}</span>
                </Popover>
              </h5>
            ) : null}
            {color ? (
              <h5
                className={styles.info_item}
                onMouseMove={() => click_keyword(id)}
                onClick={stop()}
                onMouseUp={() => click_keyword(id)}
              >
                <Popover
                  trigger="hover"
                  content={
                    <span>
                      <a href={keyword ? keywordUrl : '#'}>
                        {keyword} <SearchOutlined />
                      </a>
                      <span> {keywordTime}</span>
                    </span>
                  }
                >
                  {color}
                  <span style={{ display: 'none' }}>{id}</span>
                </Popover>
              </h5>
            ) : null}
            {currency ? (
              <h5
                className={styles.info_item}
                onMouseMove={() => click_keyword(id, 'price')}
                onClick={stop()}
                onMouseUp={() => click_keyword(id, 'price')}
              >
                <Popover
                  trigger="hover"
                  content={
                    <span>
                      <a href={keyword ? keywordUrl : '#'}>
                        {keyword} <SearchOutlined />
                      </a>
                      <span> {keywordTime}</span>
                    </span>
                  }
                >
                  {Math.ceil(price)}
                  <span style={{ display: 'none' }}>{id}</span> {currency}
                </Popover>
              </h5>
            ) : null}
          </div>
        </footer>
      </div>
      {onZoom && id ? (
        <a className={styles.zoom_btn} onClick={stop(() => onZoom())}>
          <ZoomInOutlined />
        </a>
      ) : null}
    </li>
  );
};

export const OfferSelectableItem: FC<
  SelectableItemProps<OfferForVerification> & {
    spu: SpuForVerification;
    children?: ReactNode;
  }
> = ({
  spu,
  data: {
    imgUrl,
    offerName,
    offerId,
    isOfficial,
    images,
    color,
    price,
    currency,
    retailer,
    groupId,
    composition,
    normalizedStyleId,
  },
  checked,
  onClick,
  onZoom,
  children,
}) => {
  const [query_datas] = useUrlQueryState<LabelUrlQuery>();
  const style_id = sessionStorage.getItem('styleId');
  const commonStart = findCommonStartsWith(style_id, truncateText({ text: normalizedStyleId, maxLength: 20 }));
  let [query_gender, query_category1, query_category2] = [query_datas.gender, query_datas.c1, query_datas.c2] as [
    Gender,
    string,
    string
  ];
  let query_brand = query_datas.brand;
  const history = useHistory();
  const current_tabs = history['location']['pathname'];
  const [keyword, setKeyword] = useState('');
  const [keywordUrl, setKeywordUrl] = useState('');
  const [keywordTime, setKeywordTime] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);
  const click_keyword = (id, action = 'name') => {
    const selectedKeyword = window.getSelection().toString();
    if (!selectedKeyword) return;
    const node_id = window.getSelection().anchorNode.nextSibling
      ? window.getSelection().anchorNode.nextSibling.textContent
      : null;
    if (id != node_id) return;
    setKeyword(selectedKeyword);

    // 设置关键字上一次使用时间
    query_gender = query_gender ? query_gender : spu.gender;
    query_category1 = query_category1 ? query_category1 : spu.category1;
    query_category2 = query_category2 ? query_category2 : spu.category2;
    query_brand = query_brand ? query_brand : spu.brand;
    if (current_tabs.includes('verify')) {
      query_category2 = 'all';
    }
    const timeoutKey = [];
    const keyName = [query_gender, query_brand, query_category1, query_category2, selectedKeyword?.toLowerCase()];
    keyName.filter(data => (data ? timeoutKey.push(data) : timeoutKey));
    const timeout_key = timeoutKey.join('-');
    const keywordTimeout = localStorage.getItem('keywordTime') ? JSON.parse(localStorage.getItem('keywordTime')) : {};
    if (!Object.keys(keywordTimeout).includes(timeout_key)) {
      keywordTimeout[timeout_key] = getCurrentTime();
      localStorage.setItem('keywordTime', JSON.stringify(keywordTimeout));
    }
    const timeAgo = getTimeAgo(Date.parse(keywordTimeout[timeout_key]));
    setKeywordTime(timeAgo);
    if (query_brand != 'undefined') {
      if (action == 'price') {
        setKeywordUrl(
          url.label(LabelType.Combine, {
            gender: query_gender,
            c1: query_category1,
            c2: query_category2,
            brand: query_brand,
            priceKeyword: selectedKeyword,
          })
        );
      } else {
        setKeywordUrl(
          url.label(LabelType.Combine, {
            gender: query_gender,
            c1: query_category1,
            c2: query_category2,
            brand: query_brand,
            keyword: selectedKeyword,
          })
        );
      }
    } else {
      if (action == 'price') {
        setKeywordUrl(
          url.label(LabelType.Combine, {
            gender: query_gender,
            c1: query_category1,
            c2: query_category2,
            priceKeyword: selectedKeyword,
          })
        );
      } else {
        setKeywordUrl(
          url.label(LabelType.Combine, {
            gender: query_gender,
            c1: query_category1,
            c2: query_category2,
            keyword: selectedKeyword,
          })
        );
      }
    }
  };
  return (
    <li
      className={classNames(styles.selectable_item, {
        [styles.selectable_item_selected]: checked,
      })}
      onClick={() => onClick()}
    >
      <div
        className={styles.img_wrapper}
        onMouseOver={() => setShowOverlay(true)}
        onMouseOut={() => setShowOverlay(false)}
      >
        <Img className={styles.img} src={imgUrl} alt="Story" />
        {images.length >= 2 ? <Img className={styles.alt_img} src={images[1]} alt="Story" /> : null}
        {isOfficial ? <StarFilled className={styles.icon} /> : null}
        {showOverlay && composition ? (
          <div className={styles.composition}>{truncateText({ text: composition })} </div>
        ) : null}
      </div>
      <footer className={styles.footer}>
        {children}
        <div className={styles.info}>
          <div onClick={stop()} className={styles.name_line}>
            <h1
              className={classNames(styles.name, styles.dualline)}
              onMouseMove={() => click_keyword(groupId)}
              onClick={stop()}
              onMouseUp={() => click_keyword(groupId)}
            >
              <Popover
                trigger="hover"
                content={
                  <span>
                    <a href={keyword ? keywordUrl : '#'}>
                      {keyword} <SearchOutlined />
                    </a>{' '}
                    <span> {keywordTime}</span>
                  </span>
                }
              >
                {offerName}
                <span style={{ display: 'none' }}>{groupId}</span>
              </Popover>
            </h1>
            <a
              target="_blank"
              href={
                webUrl.init() +
                'user/' +
                retailer
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .replace(/[^a-z0-9 /-]+/g, '')
                  .replace(/[ /]+/g, '') +
                '/listings/' +
                spu.spuId
              }
              className={styles.name_icon}
            >
              <IconFont type="icon-fenxiang" />
            </a>
          </div>
          {normalizedStyleId ? (
            <h5
              className={styles.info_item}
              onMouseMove={() => click_keyword(groupId)}
              onClick={stop()}
              onMouseUp={() => click_keyword(groupId)}
            >
              <Popover
                trigger="hover"
                content={
                  <span>
                    <a href={keyword ? keywordUrl : '#'}>
                      {keyword} <SearchOutlined />
                    </a>
                    <span> {keywordTime}</span>
                  </span>
                }
              >
                <HighlightText highlight={commonStart} id_obj={<span style={{ display: 'none' }}>{groupId}</span>}>
                  {truncateText({ text: normalizedStyleId, maxLength: 20 })}
                </HighlightText>
                <span style={{ display: 'none' }}>{groupId}</span>
              </Popover>
            </h5>
          ) : null}
          {color ? (
            <h5
              className={styles.info_item}
              onMouseMove={() => click_keyword(groupId)}
              onClick={stop()}
              onMouseUp={() => click_keyword(groupId)}
            >
              <Popover
                trigger="hover"
                content={
                  <span>
                    <a href={keyword ? keywordUrl : '#'}>
                      {keyword} <SearchOutlined />
                    </a>
                    <span> {keywordTime}</span>
                  </span>
                }
              >
                {color}
                <span style={{ display: 'none' }}>{groupId}</span>
              </Popover>
            </h5>
          ) : null}
          {currency ? (
            <h5
              className={styles.info_item}
              onMouseMove={() => click_keyword(groupId, 'price')}
              onClick={stop()}
              onMouseUp={() => click_keyword(groupId, 'price')}
            >
              <Popover
                trigger="hover"
                content={
                  <span>
                    <a href={keyword ? keywordUrl : '#'}>
                      {keyword} <SearchOutlined />
                    </a>
                    <span> {keywordTime}</span>
                  </span>
                }
              >
                {Math.ceil(price)}
                <span style={{ display: 'none' }}>{groupId}</span> {currency}
              </Popover>
            </h5>
          ) : null}
        </div>
      </footer>
      {onZoom ? (
        <a className={styles.zoom_btn} onClick={stop(() => onZoom())}>
          <ZoomInOutlined />
        </a>
      ) : null}
    </li>
  );
};

export const CombineProvider: FC<{
  source: LabelSourceEnum;
  children?: ReactNode;
  onCombineFinish(combineResult: SpuCombineResult): void;
}> = ({ onCombineFinish, source, children }) => {
  const [combineModalState, openCombineModal, closeCombineModal] = useModal<{
    spu0: SimilarProduct;
    spu1: SimilarProduct;
  }>(null);
  const [{ gender, c1, c2 }] = useUrlQueryState<LabelUrlQuery>();
  const combine_keyword =
    source == LabelSourceEnum.KEYWORD_SEARCH ? sessionStorage.getItem('combine_keyword') : undefined;

  const onRequestCombine = useCallback(async () => {
    const {
      data: { spu0, spu1 },
    } = combineModalState;
    await record_work(LabelType.Combine, JSON.stringify([spu0.id, spu1.id]), gender, c1, c2);
    const closeMessage = message.loading('正在合并商品', 0);
    const baseQuery = {
      forceCombine: false,
      forceCombineBrand: false,
      spuId0: spu0.id,
      spuId1: spu1.id,
      source,
      keyword: combine_keyword == 'undefined' ? undefined : combine_keyword,
    };
    const force_combine = async baseQuery => {
      try {
        const { data } = await apiService.postCombineProduct({
          ...baseQuery,
        });
        closeCombineModal();
        onCombineFinish(data);
      } catch (e) {
        if (e && (e.msg == 'Both spu has user interactions' || e.msg == 'Both spu are not the same brand')) {
          const result = await awaitCombineConfirm(e.msg);
          if (result) {
            // 强制合并
            if (e.msg == 'Both spu has user interactions') {
              baseQuery['forceCombine'] = true;
              baseQuery['forceCombineBrand'] = true;
            } else {
              baseQuery['forceCombine'] = false;
              baseQuery['forceCombineBrand'] = true;
            }
            await force_combine(baseQuery);
          }
        } else {
          message.error(e?.msg || '未知服务错误');
        }
      }
    };
    await force_combine(baseQuery);
    closeMessage();
  }, [combineModalState, onCombineFinish, source]);

  const onCombine = useCallback(
    (spu0: SimilarProduct, spu1: SimilarProduct) =>
      openCombineModal({
        spu0,
        spu1,
      }),
    [openCombineModal]
  );

  return (
    <>
      <combineContext.Provider value={onCombine}>{children}</combineContext.Provider>
      <Modal
        open={combineModalState.visible}
        onCancel={closeCombineModal}
        onOk={() => onRequestCombine()}
        okText="确定合并"
        cancelText="取消"
        title="确定要合并以下商品吗？"
        width="560px"
      >
        {combineModalState.data ? ( // TODO: cols = 2
          <div className={styles.combine_modal_content}>
            <SpuSelectableItem data={combineModalState.data.spu0} />
            <SpuSelectableItem data={combineModalState.data.spu1} />
          </div>
        ) : null}
      </Modal>
    </>
  );
};
