import React, { Suspense, FC } from 'react';
import { createRoot } from 'react-dom/client';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as serviceWorker from './serviceWorker';
import { AppRoutes } from './router';
import { PureAntdLoading } from './ui/pure-antd-loading';
import { Login } from './view/login';
import { useAccountState } from './store/account';
import { StoreProvider } from './store';

import './index.less';
import './style/font/font.less';
import { ConfigProvider } from 'antd';

let timeId;
let timeOuT = 30;
const listen_event = () => {
  document.addEventListener('click', event => {
    sessionStorage.setItem('record_time', '0');
  });
  document.addEventListener('mousemove', event => {
    sessionStorage.setItem('record_time', '0');
  });
};
const update_time = () => {
  const tab_number = sessionStorage.getItem('Tabs_number');
  timeOuT = tab_number != '1' ? 60 : 30;
  const record_time = sessionStorage.getItem('record_time');
  const listener = sessionStorage.getItem('listener');
  if (Number(record_time) < timeOuT) {
    const last_time = Number(record_time) + 1;
    const listener_time = Number(listener) + 1;
    sessionStorage.setItem('record_time', last_time.toString());
    sessionStorage.setItem('listener', listener_time.toString());
  }
};

const App: FC = () => {
  const [{ loading, ready, data: accountInfo }] = useAccountState();

  if (loading) {
    return <PureAntdLoading size="large" tip="正在获取账号信息" />;
  }

  if (!ready) return null;

  if (!accountInfo) {
    // 未登录，故没有用户信息
    return <Login />;
  }
  // 开启计时器
  clearInterval(timeId);
  timeId = setInterval(update_time, 1000);
  listen_event();

  return (
    <Suspense fallback={<>Page Loading...</>}>
      <AppRoutes />
    </Suspense>
  );
};

const root = createRoot(document.getElementById('root'));

root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#555',
        // borderRadius: 2
      },
    }}
  >
    <DndProvider backend={HTML5Backend}>
      <StoreProvider>
        <App />
      </StoreProvider>
    </DndProvider>
  </ConfigProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
