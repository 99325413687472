import { createStore } from '@@/react-store';
import { FC, ReactNode } from 'react';

const store = createStore();

export const createModel = store.createModel;

export const StoreProvider = store.Provider as FC<{
  children: ReactNode;
}>;
