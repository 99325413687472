/**
 * 常量约定
 * @since 2020-09-10 10:15:53
 */

export const DraggableTypes = {
  SPU_ITEM: 'spu-item',
};

export const ALL_VALUE = 'all';
