/**
 * 对图片的代理，在图片加载完毕之前，展示回退图片
 */

import React, { FC, MouseEvent, CSSProperties, memo, ReactNode } from 'react';
import classnames from 'classnames';
import { useFlowEffect } from '@@/react-hooks';
import { loadImage } from '@@/utils';
import styles from './index.module.less';

interface ImgProps {
  src: string;
  alt?: string;
  className?: string;
  style?: CSSProperties;
  onClick?(e: MouseEvent): void;
  children?: ReactNode;
}

export const Img: FC<ImgProps> = memo(({ src, className = '', children, ...rest }) => {
  const imageState = useFlowEffect(
    null,
    function* ({ put, call, cancel }) {
      if (!src) return;
      yield cancel();
      const imageDom = yield call(loadImage, src);
      yield put(imageDom);
    },
    [src]
  );

  if (imageState.loading) {
    if (React.Children.count(children)) {
      return <>{children}</>;
    }
    return <span className={classnames(className, styles.hint)}>图片加载中</span>;
  }

  if (imageState.error) {
    return <span className={classnames(className, styles.hint)}>图片加载失败</span>;
  }

  if (!src) {
    return <span className={classnames(className, styles.no_image)}>没有图片</span>;
  }

  return <img className={className} src={src} alt="with-fallback" {...rest} />;
});
