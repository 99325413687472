import React, { FC, useCallback, useContext, useEffect, useRef } from 'react';
import { Form, Cascader, Button, Select, FormInstance, DatePicker } from 'antd';
import { VerifyFormValues } from './interface';
import styles from './home.module.less';
import { homeContext } from './context';
import { Gender, LabelType } from '@/enum';
import { url } from '@/url';
import { useHistory } from 'react-router-dom';
import { BaseOptionType } from 'antd/lib/cascader';
import { useFlow, CallNext } from '@@/react-hooks';
import { getDesignerVerifyCount } from '@/service';
import { GetDesignerVerifyCount } from '@/service/interface';
import { ALL_VALUE } from '@/const';
import * as apiService from '@/service';
import { GetDesigners } from '@/service/interface';

export const VerifyForm: FC = () => {
  const {
    commonFormItemProps,
    categoryCascaderOptionsState,
    genderDesignerOptionsDictState,
    genderRetailersOptionsDictState,
    labelerListState,
  } = useContext(homeContext);
  const category = sessionStorage.getItem('verify_category')
    ? sessionStorage.getItem('verify_category').split(',')
    : '';
  const labeler = sessionStorage.getItem('verify_labeler');
  const brand = sessionStorage.getItem('verify_brand');
  const retailer = sessionStorage.getItem('verify_retailer');

  const [designerOptionsState, dispatch] = useFlow(
    [] as {
      brand: string;
      count: number;
    }[],
    function* ({ call, put, cancel }, category: BaseOptionType) {
      if (!category) return;
      yield cancel();
      yield put([]);
      const [gender, category1, category2] = category as [Gender, string, string];
      const { data: brandCountDict }: CallNext<GetDesignerVerifyCount> = yield call(getDesignerVerifyCount, {
        gender,
        category1,
        category2: category2 === ALL_VALUE ? undefined : category2,
      });
      const { data }: CallNext<GetDesigners> = yield call(apiService.getDesigners);
      yield put(
        (data[gender] || [])
          .map(brand => ({
            brand,
            count: brandCountDict[brand] ?? 0,
          }))
          .sort((a, b) => b.count - a.count)
      );
    }
  );

  const formRef = useRef<FormInstance>();

  useEffect(() => {
    dispatch(formRef.current.getFieldsValue().category);
  }, []);

  const onValuesChange = useCallback(changes => {
    if (changes.category) {
      dispatch(changes.category);
    }
    const form = formRef.current;
    const { category, brand } = form.getFieldsValue();
    const gender: Gender = category?.[0];
    if (gender && brand && !genderDesignerOptionsDictState.data[gender].includes(brand)) {
      form.setFieldsValue({
        brand: brand,
      });
    }
  }, []);

  const history = useHistory();
  const onStartVerify = useCallback(
    ({ category: [gender, c1, c2], labeler, brand, retailer, range }: VerifyFormValues) => {
      const [start, end] = range || [undefined, undefined];
      history.push(
        url.label(LabelType.Verify, {
          gender,
          c1,
          c2,
          labeler: labeler || undefined,
          brand: brand || undefined,
          retailer: retailer || undefined,
          start: start && start.startOf('day').format('YYYY-MM-DD'),
          end: end && end.endOf('day').format('YYYY-MM-DD'),
        })
      );
    },
    [history]
  );

  return (
    <Form<VerifyFormValues>
      ref={formRef}
      initialValues={{
        category: category ? category : undefined,
        labeler: labeler != 'undefined' ? labeler : '',
        brand: brand != 'undefined' ? brand : '',
        retailer: retailer != 'undefined' ? retailer : '',
        range: undefined,
      }}
      onValuesChange={onValuesChange}
      className={styles.form}
      size="large"
      onFinish={onStartVerify}
    >
      <Form.Item
        {...commonFormItemProps}
        label="类别"
        name="category"
        required
        shouldUpdate={() => true}
        rules={[{ required: true, message: '请选择类别' }]}
      >
        <Cascader placeholder="请选择商品类别" options={categoryCascaderOptionsState.data} />
      </Form.Item>
      <Form.Item {...commonFormItemProps} label="标注账号" name="labeler">
        <Select placeholder="选择需要指定审核的标注账号" loading={labelerListState.loading}>
          <Select.Option value="">全部</Select.Option>
          {labelerListState.data.map(labeler => (
            <Select.Option key={labeler} value={labeler}>
              {labeler}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item<VerifyFormValues> label="品牌" name="brand" {...commonFormItemProps}>
        <Select
          loading={designerOptionsState.loading}
          showSearch
          filterOption={true}
          placeholder="选择需要指定审核的品牌名称"
        >
          <Select.Option value="">全部</Select.Option>
          {designerOptionsState.data.map(({ brand, count }) => (
            <Select.Option key={brand} value={brand}>
              <div className={styles.designer_option}>
                <span>{brand}</span>
                <span className={styles.designer_count}>{count}</span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item {...commonFormItemProps} label="零售商" name="retailer">
        <Select placeholder="请选择需要指定的零售商" showSearch loading={genderRetailersOptionsDictState.loading}>
          <Select.Option value="">全部</Select.Option>
          {genderRetailersOptionsDictState.data.map(labeler => (
            <Select.Option key={labeler} value={labeler}>
              {labeler}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item {...commonFormItemProps} label="标注日期" name="range">
        <DatePicker.RangePicker className={styles.range_picker} placeholder={['开始日期', '结束日期']} />
      </Form.Item>
      <Button htmlType="submit" type="primary" size="large" className={styles.button}>
        开始审核
      </Button>
    </Form>
  );
};
