/**
 * for multi image display
 * @since 2020-09-13 15:50:21
 */

import React, { FC, useState, useEffect, useMemo, useCallback, memo } from 'react';
import classnames from 'classnames';
import { noop } from '@@/utils';
import styles from './index.module.less';
import { LeftOutlined, RightOutlined, StarFilled } from '@ant-design/icons';
import { Img } from '../img';
import { cloneDeep } from 'lodash';
import ImagesDragSort from './drag-sort';
import { CloseOutlined } from '@ant-design/icons';

interface GalleryProps {
  data: string[] | string;
  isOfficial?: boolean;
  onChangeImagesOrders?(imagesOrders: string[]): void;
}

export const Gallery: FC<GalleryProps> = memo(({ data, isOfficial, onChangeImagesOrders }) => {
  const [state, setState] = useState({
    index: 0,
    sources: [] as string[],
  });

  useEffect(() => {
    setState({
      index: 0,
      sources: Array.isArray(data) ? data : [data],
    });
  }, [data]);

  const currentImage = useMemo(() => state.sources[state.index], [state]);

  const changePage = useCallback(
    (reducer: (prev: number) => number) => () => {
      setState(prev => {
        const reducedIndex = reducer(prev.index);
        const targetIndex =
          reducedIndex < 0 ? prev.sources.length - 1 : reducedIndex > prev.sources.length - 1 ? 0 : reducedIndex;
        return {
          ...prev,
          index: targetIndex,
        };
      });
    },
    []
  );

  const changePosition = (dragIndex: any, hoverIndex: any) => {
    const data = cloneDeep(state.sources);
    const temp = data[dragIndex];
    data[dragIndex] = data[hoverIndex];
    data[hoverIndex] = temp;
    setState({ index: 0, sources: data });
    onChangeImagesOrders(data);
  };

  const onDelete = index => {
    const data = cloneDeep(state.sources);
    data.splice(index.index, 1);
    setState({ index: 0, sources: data });
    onChangeImagesOrders(data);
  };

  return (
    <div className={styles.gallery}>
      <div className={styles.major}>
        <Img className={styles['major-image']} alt="main" src={currentImage} />
        <div className={classnames(styles.btn, styles['btn--left'])} onClick={changePage(prev => prev - 1)}>
          <LeftOutlined />
        </div>
        <div className={classnames(styles.btn, styles['btn--right'])} onClick={changePage(prev => prev + 1)}>
          <RightOutlined />
        </div>
        {isOfficial ? <StarFilled className={styles.icon} /> : null}
      </div>
      <footer className={styles.footer}>
        {state.sources.map((src, index) => {
          const isCurrent = index === state.index;
          return (
            <ImagesDragSort
              id={index}
              index={index}
              key={index}
              rowKey={index}
              changePosition={changePosition}
              className={styles.footer}
              ischange={onChangeImagesOrders}
            >
              <div
                className={classnames({
                  [styles.thumbnail]: true,
                  [styles['thumbnail--current']]: isCurrent,
                })}
                style={{ position: 'relative' }}
                key={index}
                onClick={isCurrent ? noop : () => setState(prev => ({ ...prev, index }))}
              >
                <span className={styles.overlays}>
                  <a className={styles.btn_out} onClick={() => onDelete({ index })}>
                    <CloseOutlined />
                  </a>
                </span>
                <Img className={styles['thumbnail-image']} src={src} alt="thumbnail" />
              </div>
            </ImagesDragSort>
          );
        })}
      </footer>
    </div>
  );
});
