import { LabelUrlQuery } from '@/interface/url';
import { URLUtils } from '@@/utils';
import { createAbsoluteUrlFactoryCollection } from '@reversible/frontend-urls';
import { LabelType } from './enum';
import { DEPLOY_ENV } from './env';
/**
 * url 的工厂函数组成的模块
 */
export const url = {
  home: (): string => '/', // 主页
  // 标注
  label: (type: LabelType | string, labelerQuery: LabelUrlQuery = {}): string =>
    URLUtils.joinParams(`/label/${type}`, labelerQuery),
  report: (): string => '/report',
  details: (): string => '/report/label-details',
  category: (): string => '/report/label-category',
  online: (): string => '/report/label-online',
  refresh: (): string => '/report/label-refresh',
};

const WEB_ORIGIN = {
  local: 'https://www.reversible.dev',
  dev: 'https://www.reversible.dev',
  live: 'https://www.reversible.live',
  prod: 'https://www.reversible.com',
}[DEPLOY_ENV];
/**
 * absolute url for web
 */
export const webUrl = createAbsoluteUrlFactoryCollection(WEB_ORIGIN);
