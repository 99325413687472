import React, { FC, useEffect, useCallback, useState } from 'react';
import { Checkbox, Empty, Modal } from 'antd';
import { SpuCombineResult } from '@/interface/label';
import { ItemForVerification, SimilarProduct, CombineProduct } from '@/interface/label';
import { useModal } from '@@/react-hooks';
import { ArrayUtils } from '@@/utils';
import { Gallery } from '@/ui/gallery';
import { ItemSelect, SpuSelectableItem, CombineProvider } from '../../item-select';
import { LabelSourceEnum } from '@/enum';

import commonStyles from '../../../common.module.less';

interface SimilarSpusProps {
  data: ItemForVerification | CombineProduct;
  selectedSpuIds: number[];
  onChangeSelectedSpuIds(spuIds: number[]): void;
}

export const SimilarSpus: FC<SimilarSpusProps> = ({ data, selectedSpuIds, onChangeSelectedSpuIds }) => {
  const [zoomModalState, openZoomModal, closeZoomModal] = useModal<SimilarProduct>(null);

  const [similarProducts, setSimilarProducts] = useState([]);
  const [keywordDeleteSpu, setkeywordDeleteSpu] = useState([]);

  useEffect(() => {
    setSimilarProducts(
      'similarProducts' in data
        ? data.similarProducts.slice(0, 5)
        : data.keywordProducts.filter(spu => !keywordDeleteSpu.includes(spu.id))
    );
  }, [data]);

  const cols = 'similarProducts' in data ? 2 : 6;
  const source = 'similarProducts' in data ? LabelSourceEnum.VERIFY_DRAG : LabelSourceEnum.KEYWORD_SEARCH;
  const onCombineFinish = useCallback(
    (combineResult: SpuCombineResult) => {
      const { toDelete, toKeep, prioritySpuId } = combineResult;
      if (selectedSpuIds.includes(toDelete)) {
        const nextSelectedSpuIds = ArrayUtils.unique(selectedSpuIds.map(id => (id === toDelete ? toKeep : id)));
        onChangeSelectedSpuIds(nextSelectedSpuIds);
      }
      // 如果toDelete优先级更高, 保留toDelete的spu信息，keep的id
      if (toDelete == prioritySpuId) {
        const randomId = Math.floor(Math.random() * Math.pow(10, 9));
        similarProducts.filter(spu => (spu.id == toKeep ? (spu.id = randomId) : null));
        similarProducts.filter(spu => (spu.id == toDelete ? (spu.id = toKeep) : null));
        similarProducts.filter(spu => (spu.id == randomId ? (spu.id = toDelete) : null));
      }
      setSimilarProducts(prev => prev.filter(({ id }) => id !== toDelete));
      keywordDeleteSpu.push(toDelete);
      setkeywordDeleteSpu(keywordDeleteSpu);
    },
    [onChangeSelectedSpuIds, selectedSpuIds]
  );

  const selectedCount = selectedSpuIds.length;

  if (!similarProducts.length) {
    return (
      <div className={commonStyles.vertical_container}>
        <div className={commonStyles.empty}>
          <Empty description="没有找到相似的商品" />
        </div>
      </div>
    );
  }

  return (
    <div className={commonStyles.vertical_container}>
      <header className={commonStyles.header}>
        <h3 className={commonStyles.title}>请选择可以合并的商品</h3>
        {selectedCount ? <span className={commonStyles.count}>已选中 {selectedCount} 项</span> : null}
      </header>
      <ItemSelect cols={cols}>
        <CombineProvider source={source} onCombineFinish={onCombineFinish}>
          {similarProducts.map(data => {
            const checked = selectedSpuIds.includes(data.id);
            const onClick = () =>
              onChangeSelectedSpuIds(
                checked ? selectedSpuIds.filter(item => item !== data.id) : [...selectedSpuIds, data.id]
              );
            const onZoom = () => openZoomModal(data);
            return (
              <SpuSelectableItem key={data.id} checked={checked} data={data} onClick={onClick} onZoom={onZoom}>
                <Checkbox className={commonStyles.checker} checked={checked} />
              </SpuSelectableItem>
            );
          })}
        </CombineProvider>
      </ItemSelect>
      <Modal
        open={zoomModalState.visible}
        onCancel={closeZoomModal}
        title={null}
        footer={null}
        width="33vw"
        mask={false}
        maskClosable={false}
        style={{ left: '67vw', margin: 0 }}
      >
        {zoomModalState.data && <Gallery data={zoomModalState.data.images} />}
      </Modal>
    </div>
  );
};
