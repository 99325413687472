import { useUrlQueryState } from '@reversible/common/es/react-hooks';
import React, { FC, ComponentType } from 'react';
import { Redirect } from 'react-router';
import { GENDERS, LabelType, availableLabelTypes } from '@/enum';
import { LabelUrlQuery } from '@/interface/url';
import { useAccountInfo } from '@/store/account';
import { url } from '@/url';
import { ClassifyLabeler } from './component/classify';
import { VerifyLabeler } from './component/verify';
import { CombineLabeler } from './component/combine';
import { CombineStyleIdLabeler } from './component/combine-styleId';
import * as apiService from '@/service';

const TypeLabelerDict: Record<LabelType, ComponentType> = {
  [LabelType.Classify]: ClassifyLabeler,
  [LabelType.Verify]: VerifyLabeler,
  [LabelType.Combine]: CombineLabeler,
  [LabelType.StyleId]: CombineStyleIdLabeler,
};

export const getCurrentTime = () => {
  const currentDate: Date = new Date()
  const currentYear: number = currentDate.getFullYear()
  const currentMonth: number = currentDate.getMonth() + 1;
  const currentDay: number = currentDate.getDate()
  const currentHour: number = currentDate.getHours();
  const currentMinutes: number = currentDate.getMinutes();
  const currentSeconds: number = currentDate.getSeconds();
  let month = currentMonth.toString()
  let day = currentDay.toString()
  let hours = currentHour.toString()
  let minutes = currentMinutes.toString()
  let seconds = currentSeconds.toString()
  if (currentMonth >= 1 && currentMonth <= 9) {
    month = "0" + currentMonth.toString();
  }
  if (currentDay >= 0 && currentDay <= 9) {
    day = "0" + currentDay.toString();
  }
  if (currentHour >= 0 && currentHour <= 9) {
    hours = "0" + currentHour.toString();
  }
  if (currentMinutes >= 0 && currentMinutes <= 9) {
    minutes = "0" + currentMinutes.toString();
  }
  if (currentSeconds >= 0 && currentSeconds <= 9) {
    seconds = "0" + currentSeconds.toString();
  }
  const currentTime = `${currentYear}-${month}-${day} ${hours}:${minutes}:${seconds}`
  return currentTime
}

export const getTimeAgo = (timestamp) => {
  const currentTime = new Date().getTime();
  const timeDiff = currentTime - timestamp;
  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  if (timeDiff < minute) {
    if (Math.floor(timeDiff / (1000)) >= 1) {
      return Math.floor(timeDiff / (1000)) + '秒前';
    }
  } else if (timeDiff < hour) {
    return Math.floor(timeDiff / (minute)) + '分钟前';
  } else if (timeDiff < day) {
    return Math.floor(timeDiff / (hour)) + '小时前';
  } else {
    return Math.floor(timeDiff / day) + "天前";
  }
}

export const record_work = async (actionType, description, gender, category1, category2) => {
  // record work time
  const listener_time = sessionStorage.getItem('listener')
  await apiService.postRecord({
    diffTime: Number(listener_time),
    actionType: actionType,
    description: description,
    gender: gender,
    category1: category1,
    category2: category2,
  })
  sessionStorage.setItem('record_time', '0')
  sessionStorage.setItem('listener', '0')
}

export const Label: FC<{
  type: LabelType;
}> = ({ type }) => {
  const [query] = useUrlQueryState<LabelUrlQuery>();

  const { userStatus } = useAccountInfo();

  // 验证参数的合法性
  if (
    !availableLabelTypes(userStatus).includes(type) ||
    (!query.spuIds?.length && (!GENDERS.includes(query.gender) || !query.c1 || !query.c2))
  ) {
    return <Redirect to={url.home()} />;
  }

  const Labeler = TypeLabelerDict[type];

  return (
    <div>
      <Labeler />
    </div>
  );
};
