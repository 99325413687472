import React, { FC, useEffect } from 'react';
import { Alert, message, Select, Modal } from 'antd';
import * as apiService from '@/service';
import { useFlow, CallNext, GetNext, useUrlQueryState, useSuperState, useModal } from '@@/react-hooks';
import { OffersDisplay } from './component/offers-display';
import { SimilarSpus } from './component/similar-spus';
import { ItemForVerification, OfferGroupForVerification } from '@/interface/label';
import { PureAntdLoading } from '@/ui/pure-antd-loading';
import { LoadableAntdButton } from '@/ui/loadable-antd-button';
import { useAccountInfo } from '@/store/account';
import { LabelLayout, LabelLayoutHeader } from '../layout';
import { SpuDisplay } from '../spu-display';
import { NoMoreLabeling } from '../no-more-labeling';
import { GetVerifyData, GetSizeOptions } from '@/service/interface';
import { LabelUrlQuery } from '@/interface/url';
import { useAccountLabelInfo } from '@/store/account-label-info';
import { LabelType, LabelSourceEnum } from '@/enum';
import { MaybePromise } from '@@/util-types';
import commonStyles from '../../common.module.less';
import { url } from '@/url';
import { ALL_VALUE } from '@/const';
import { useCategoryByGenderCascaderOptions } from '@/hooks/use-category-cascader-options';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { GetCategories } from '@/service/interface';
import { uppercaseFirstLetter } from '@/util/format';
import { record_work } from '../../index';

const awaitCombineConfirm = msg =>
  new Promise<boolean>((resolve, reject) => {
    let comfirm_msg = '商品非同一个品牌，是否强制合并?';
    if (msg == 'Both spu has user interactions') {
      comfirm_msg = '商品存在用户行为，是否强制合并?';
    }
    Modal.confirm({
      title: comfirm_msg,
      icon: <ExclamationCircleFilled />,
      content: '',
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    });
  });

/**
 * submission data for correction labeling
 */
interface VerificationSubmission {
  offerGroupsWithError: OfferGroupForVerification[]; // offers with error
  spuIdsToCombine: number[]; // spuIds to combine
  changeCategory1: string;
  changeCategory2: string;
  imagesOrders: string[];
  changeSizeUnit: string;
  forceCombine?: boolean;
}
export type Action =
  | {
      type: 'productUpdate';
      payload: VerificationSubmission;
    }
  | {
      type: 'submit';
      payload: VerificationSubmission;
    };

const VerifyLabelCore: FC<{
  itemForVerification: ItemForVerification;
  dispatch(action: Action): MaybePromise<void>;
}> = ({ itemForVerification, dispatch, ...filterProps }) => {
  const [category1State, opencategory1Modal, closecategory1Modal] = useModal<{}>(null);
  const [category2State, opencategory2Modal, closecategory2Modal] = useModal<{}>(null);
  const [sizeUnitState, openSizeUnitModal, closeSizeUnitModal] = useModal<{}>(null);

  // 标注数据
  const [labelState, setLabelState] = useSuperState<VerificationSubmission>({
    offerGroupsWithError: [],
    spuIdsToCombine: [],
    changeCategory1: '',
    changeCategory2: '',
    imagesOrders: [],
    changeSizeUnit: '',
  });

  // initialize labeling state while spu mutated
  useEffect(() => {
    setLabelState({
      offerGroupsWithError: [],
      spuIdsToCombine: [],
      changeCategory1: '',
      changeCategory2: '',
      imagesOrders: [],
      changeSizeUnit: '',
    });
  }, []);

  // 内容节点
  const contentNode = (() => {
    const toggleOfferGroup = (offerGroup: OfferGroupForVerification) =>
      setLabelState(prev => {
        const offerGroupsWithError = prev.offerGroupsWithError.includes(offerGroup)
          ? prev.offerGroupsWithError.filter(item => item !== offerGroup)
          : [...prev.offerGroupsWithError, offerGroup];
        return {
          ...prev,
          offerGroupsWithError,
        };
      });

    const onChangeSelectedSpuIds = (spuIdsToCombine: number[]) =>
      setLabelState(prev => ({
        ...prev,
        spuIdsToCombine,
      }));

    const { spu, labelId } = itemForVerification;
    const { category1, category2, gender, sizeUnit } = spu;

    const categorysCascaderOptionsState = useCategoryByGenderCascaderOptions(gender);
    let category2CascaderOptionsState = [];
    const category1CascaderOptionsState = [];
    for (const options of categorysCascaderOptionsState.data) {
      category1CascaderOptionsState.push({ value: options['value'], label: options['label'] });
      const c1 = labelState.changeCategory1 ? labelState.changeCategory1 : category1;
      if (options['value'] == c1) {
        category2CascaderOptionsState = options['children'];
      }
    }

    const [sizeOptionsState, dispatch] = useFlow([] as string[], function* ({ put, call }, query: any) {
      const {
        data: { order },
      }: CallNext<GetSizeOptions> = yield call(apiService.getSizeOptions, {
        gender: gender,
        category1: query['category1'] ? query['category1'] : category1,
        category2: query['category2'] ? query['category2'] : category2,
      });
      yield put(order);
    });

    useEffect(() => {
      dispatch({ category1: category1, category2: category2 });
    }, []);

    const onChangeCategory1 = (changeCategory1: string) => {
      setLabelState(prev => ({
        ...prev,
        changeCategory1,
      }));
      dispatch({ category1: changeCategory1, category2: labelState.changeCategory2 });
    };
    const onChangeCategory2 = (changeCategory2: string) => {
      setLabelState(prev => ({
        ...prev,
        changeCategory2,
      }));
      dispatch({ category1: labelState.changeCategory1, category2: changeCategory2 });
    };

    const onChangeSizeUnit = (changeSizeUnit: string) => {
      setLabelState(prev => ({
        ...prev,
        changeSizeUnit,
      }));
    };

    const closeChangeCategory = () => {
      closecategory1Modal();
      onChangeCategory1('');
      closecategory2Modal();
      onChangeCategory2('');
      closeSizeUnitModal();
    };

    const onChangeImagesOrders = (imagesOrders: string[]) => {
      setLabelState(prev => ({
        ...prev,
        imagesOrders,
      }));
    };

    return (
      <>
        <div className={commonStyles.left_of_3}>
          <div className={commonStyles.vertical_container}>
            <header className={commonStyles.header}>
              <h3 className={commonStyles.title} onClick={closeChangeCategory}>
                当前商品
              </h3>
              <span className={commonStyles.header_addon}>
                {gender} /{' '}
                {category1State.visible ? (
                  <Select
                    placeholder="请选择修改的类别"
                    onChange={onChangeCategory1}
                    allowClear
                    onClear={closecategory1Modal}
                  >
                    {category1CascaderOptionsState.map(data => (
                      <Select.Option key={data.label} value={data.value}>
                        {data.value}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <span onClick={opencategory1Modal} style={{ border: '1px' }}>
                    {category1}{' '}
                  </span>
                )}
                /{' '}
                {category2State.visible ? (
                  <Select
                    placeholder="请选择修改的类别"
                    onChange={onChangeCategory2}
                    allowClear
                    onClear={closecategory2Modal}
                  >
                    {category2CascaderOptionsState.map(data => (
                      <Select.Option key={data.label} value={data.value}>
                        {data.value}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <span onClick={opencategory2Modal} style={{ border: '1px' }}>
                    {category2}{' '}
                  </span>
                )}
                （尺码单元:{' '}
                {sizeUnitState.visible ? (
                  <Select
                    placeholder="请选择修改的unit"
                    loading={sizeOptionsState.loading}
                    onChange={onChangeSizeUnit}
                    allowClear
                    onClear={closeSizeUnitModal}
                  >
                    {sizeOptionsState.data.map(labeler => (
                      <Select.Option key={labeler} value={labeler}>
                        {labeler}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <span onClick={openSizeUnitModal} style={{ border: '1px' }}>
                    {sizeUnit}
                  </span>
                )}
                ）
              </span>
            </header>
            <SpuDisplay data={spu} onChangeImagesOrders={onChangeImagesOrders} />
          </div>
        </div>

        <div className={commonStyles.mid_of_3}>
          <OffersDisplay
            spu={spu}
            selectedOfferGroups={labelState.offerGroupsWithError}
            toggleOfferGroup={toggleOfferGroup}
            labelId={labelId}
            onCreateNewFinish={data => {
              setLabelState({ offerGroupsWithError: data });
            }}
          />
        </div>

        <div className={commonStyles.right_of_3}>
          <SimilarSpus
            data={itemForVerification}
            selectedSpuIds={labelState.spuIdsToCombine}
            onChangeSelectedSpuIds={onChangeSelectedSpuIds}
          />
        </div>
      </>
    );
  })();

  return (
    <LabelLayout>
      <LabelLayoutHeader {...filterProps} type={LabelType.Verify}>
        {labelState.changeCategory1 ||
        labelState.changeCategory2 ||
        labelState.changeSizeUnit ||
        labelState.imagesOrders.length != 0 ? (
          <LoadableAntdButton onClick={() => dispatch({ type: 'productUpdate', payload: labelState })}>
            信息修改
          </LoadableAntdButton>
        ) : null}
        <span style={{ width: '10px' }}></span>
        <LoadableAntdButton onClick={() => dispatch({ type: 'submit', payload: labelState })}>提交</LoadableAntdButton>
      </LabelLayoutHeader>
      {contentNode}
    </LabelLayout>
  );
};

interface VerificationState {
  queue: ItemForVerification[];
  index: number;
}

export const VerifyLabeler: FC = () => {
  const accountInfo = useAccountInfo();

  const history = useHistory();

  const [{ gender, c1, c2, labeler, brand, retailer, start, end, spuIds }] = useUrlQueryState<LabelUrlQuery>();

  const [, setAccountLabelInfo] = useAccountLabelInfo();

  const [
    {
      data: { queue, index },
      loading,
      ready,
      error,
    },
    dispatch,
  ] = useFlow<VerificationState, Action | void>(
    {
      queue: [],
      index: 0,
    },
    function* ({ call, put, get, getCount }, action) {
      if (action && action.type == 'productUpdate') {
        const { imagesOrders, changeCategory1, changeCategory2, changeSizeUnit } = action.payload;
        const { index: currentIndex, queue: currentQueue }: GetNext<VerificationState> = yield get();
        const {
          spu: { spuId, category2, gender },
        } = currentQueue[currentIndex];
        const { data }: CallNext<GetCategories> = yield call(apiService.getCategories);
        const gender_item = gender == 'women' ? data['women'] : data['men'];
        const categoryOption = Object.entries(gender_item).map(([value, subCategories]) => ({
          value,
          label: uppercaseFirstLetter(value),
          children: [
            ...subCategories.map(subValue => ({
              value: subValue,
              label: uppercaseFirstLetter(subValue),
            })),
          ],
        }));
        for (const option of categoryOption) {
          if (changeCategory1 && changeCategory1 === option['value']) {
            const category2_option = [];
            for (const data of option['children']) {
              category2_option.push(data.value);
            }
            if (
              (changeCategory2 && !category2_option.includes(changeCategory2)) ||
              (!changeCategory2 && !category2_option.includes(category2))
            ) {
              message.error('当前c1与c2不匹配');
              return;
            }
          }
        }
        if (changeCategory1 || changeCategory2 || changeSizeUnit || imagesOrders.length != 0) {
          try {
            yield call(apiService.putProducts, {
              category1: changeCategory1 ? changeCategory1 : undefined,
              category2: changeCategory2 ? changeCategory2 : undefined,
              spuId: spuId,
              sizeUnit: changeSizeUnit ? changeSizeUnit : undefined,
              images: imagesOrders.length != 0 ? imagesOrders : undefined,
            });
            message.success('信息修改成功!');
          } catch (e) {
            message.error(e?.msg || e?.message || '修改失败，请重试');
          }

          const { data }: CallNext<GetVerifyData> = yield call(apiService.getVerifyData, {
            gender,
            category1: c1,
            category2: c2 === ALL_VALUE ? undefined : c2,
            spu_id: [spuId],
          });

          yield put({
            queue: data.verifyData,
            index: 0,
          });
          return;
        } else {
          message.info('当前无可修改内容');
          return;
        }
      }

      if (action && action.type == 'submit') {
        // 提交
        const { offerGroupsWithError, spuIdsToCombine } = action.payload;

        const { index: currentIndex, queue: currentQueue }: GetNext<VerificationState> = yield get();

        const {
          labelId,
          spu: { spuId, images },
        } = currentQueue[currentIndex];

        record_work(LabelType.Verify, JSON.stringify({ offerGroupsWithError, spuIdsToCombine }), gender, c1, c2);
        if (offerGroupsWithError.length) {
          try {
            yield call(apiService.postVerifyData, {
              labelId,
              spuId,
              verifiedBy: accountInfo.username,
              spuImgUrl: images[0], // must be a primary image
              errorOffers: offerGroupsWithError.map(({ offers, groupId }) => ({
                errorImgUrl: offers[0].imgUrl,
                groupId,
              })),
            });
          } catch (e) {
            message.error(e?.msg || e?.message || '提交失败，请重试');
          }
        }

        let nextQueue = currentQueue;
        let cancelCombine = false;
        if (spuIdsToCombine.length) {
          let forceCombine = false;
          let forceCombineBrand = false;
          const spusToDelete = yield call(async () => {
            const list: number[] = [];
            let spuId0 = spuId;
            // async reduce
            for (const spuId1 of spuIdsToCombine) {
              const baseQuery = {
                source: LabelSourceEnum.VERIFY_SELECT,
                spuId0,
                spuId1,
                forceCombine: forceCombine,
                forceCombineBrand: forceCombineBrand,
              };
              const force_combine = async baseQuery => {
                try {
                  const {
                    data: { toKeep, toDelete, prioritySpuId },
                  } = await apiService.postCombineProduct({
                    ...baseQuery,
                  });
                  list.push(toDelete);
                  spuId0 = toKeep;
                } catch (e) {
                  if (e && (e.msg == 'Both spu has user interactions' || e.msg == 'Both spu are not the same brand')) {
                    const result = await awaitCombineConfirm(e.msg);
                    if (result) {
                      // 强制合并
                      if (e.msg == 'Both spu has user interactions') {
                        forceCombine = true;
                        forceCombineBrand = true;
                        baseQuery['forceCombine'] = true;
                        baseQuery['forceCombineBrand'] = true;
                      } else {
                        forceCombine = false;
                        forceCombineBrand = true;
                        baseQuery['forceCombine'] = false;
                        baseQuery['forceCombineBrand'] = true;
                      }
                      await force_combine(baseQuery);
                    } else {
                      cancelCombine = true;
                      return list;
                    }
                  } else {
                    message.error(e?.msg || '提交失败，请重试');
                    cancelCombine = true;
                    return list;
                  }
                }
              };
              await force_combine(baseQuery);
            }
            return list;
          });

          // 移除后续的已经被 combine 掉的 spu
          nextQueue = currentQueue
            .filter(({ spu: { spuId } }, index) => index <= currentIndex || !spusToDelete.includes(spuId))
            .map(item => ({
              ...item,
              similarProducts: item.similarProducts.filter(({ id }) => !spusToDelete.includes(id)),
            }));
        }
        if (cancelCombine) {
          return;
        }

        // 后面还有的话就向后一个
        if (nextQueue[currentIndex + 1]) {
          yield put({
            queue: nextQueue,
            index: currentIndex + 1,
          });
          return;
        }
      }

      if (spuIds) {
        const count = yield getCount();
        if (count) {
          message.success('指定 ID 审核完成');
          history.push(url.home());
          sessionStorage.setItem('Tabs_number', '3');
          return;
        }
      }

      // 拉取新数据
      const { data }: CallNext<GetVerifyData> = yield call(apiService.getVerifyData, {
        gender,
        category1: c1,
        category2: c2 === ALL_VALUE ? undefined : c2,
        labeler,
        brand,
        retailer,
        start_time: start && moment(start).format('YYYY-MM-DD HH:mm:ss'),
        end_time: end && moment(end).format('YYYY-MM-DD HH:mm:ss'),
        spu_id: spuIds,
      });

      setAccountLabelInfo(data.verifierInfo);

      yield put({
        queue: data.verifyData,
        index: 0,
      });
    }
  );

  useEffect(() => {
    dispatch();
    sessionStorage.setItem('Tabs_number', '2');
    sessionStorage.setItem('record_time', '0');
    sessionStorage.setItem('listener', '0');
  }, []);

  if (queue.length == 0) {
    if (loading) {
      return <PureAntdLoading size="large" tip="正在获取标注数据..." />;
    }

    if (error) {
      console.log('error', error);
      const errorDescription = error?.msg || error?.data || '未知错误';
      return <Alert message="获取标注数据错误" showIcon description={errorDescription} type="error" />;
    }

    if (!ready) {
      // 只会在初始时刻出现的状态
      return null;
    }
  }

  const currentItem = queue[index];

  if (!currentItem) {
    return (
      <LabelLayout>
        <LabelLayoutHeader type={LabelType.Verify} />
        <NoMoreLabeling />
      </LabelLayout>
    );
  }
  sessionStorage.setItem('styleId', currentItem.spu.normalizedStyleId);
  return <VerifyLabelCore key={currentItem.labelId} itemForVerification={currentItem} dispatch={dispatch} />;
};
