import React, { FC, memo, useState } from 'react';
import classNames from 'classnames';
import { webUrl, url } from '@/url';
import { Gallery } from '@/ui/gallery';
import styles from './spu-display.module.less';
import { ItemForClassification, SpuForVerification, SimilarProduct } from '@/interface/label';
import { useUrlQueryState } from '@@/react-hooks';
import { LabelUrlQuery } from '@/interface/url';
import { Gender, LabelType } from '@/enum';
import { stop } from '@@/utils';
import { Popover } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getCurrentTime, getTimeAgo } from '../../index';
import { createFromIconfontCN } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

interface SpuDisplayProps {
  data: SpuForVerification | ItemForClassification | SimilarProduct;
  onChangeImagesOrders?(imagesOrders: string[]): void;
}
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3910887_nc6i6ddint9.js',
});
export const SpuDisplay: FC<SpuDisplayProps> = memo(({ data, onChangeImagesOrders }) => {
  const { name, brand, images, description, color, currency, price, collabDesigner } = data;
  const spu_id = 'spuId' in data ? data.spuId : 'id' in data ? data.id : '';
  const brands = [brand];
  if (collabDesigner) {
    for (const item of collabDesigner) {
      if (item != brand) {
        brands.push(item);
      }
    }
  }
  const collab_designers = collabDesigner ? brands.join(' x ') : brand;

  const [query_datas] = useUrlQueryState<LabelUrlQuery>();
  let [query_gender, query_category1, query_category2] = [query_datas.gender, query_datas.c1, query_datas.c2] as [
    Gender,
    string,
    string
  ];
  let query_brand = query_datas.brand;
  const history = useHistory();
  const current_tabs = history['location']['pathname'];
  const [keyword, setKeyword] = useState('');
  const [keywordUrl, setKeywordUrl] = useState('');
  const [keywordTime, setKeywordTime] = useState('');
  const click_keyword = (id, action = 'name') => {
    const selectedKeyword = window.getSelection().toString();
    if (!selectedKeyword) return;
    const node_id = window.getSelection().anchorNode.nextSibling
      ? window.getSelection().anchorNode.nextSibling.textContent
      : null;
    if (id != node_id) return;
    setKeyword(selectedKeyword);

    // 设置关键字上一次使用时间
    query_gender = query_gender ? query_gender : data.gender;
    query_category1 = query_category1 ? query_category1 : data.category1;
    query_category2 = query_category2 ? query_category2 : data.category2;
    query_brand = query_brand ? query_brand : data.brand;
    if (current_tabs.includes('verify')) {
      query_category2 = 'all';
    }
    const timeoutKey = [];
    const keyName = [query_gender, query_brand, query_category1, query_category2, selectedKeyword?.toLowerCase()];
    keyName.filter(data => (data ? timeoutKey.push(data) : timeoutKey));
    const timeout_key = timeoutKey.join('-');
    const keywordTimeout = localStorage.getItem('keywordTime') ? JSON.parse(localStorage.getItem('keywordTime')) : {};
    if (!Object.keys(keywordTimeout).includes(timeout_key)) {
      keywordTimeout[timeout_key] = getCurrentTime();
      localStorage.setItem('keywordTime', JSON.stringify(keywordTimeout));
    }
    const timeAgo = getTimeAgo(Date.parse(keywordTimeout[timeout_key]));
    setKeywordTime(timeAgo);
    if (query_brand != 'undefined') {
      if (action == 'price') {
        setKeywordUrl(
          url.label(LabelType.Combine, {
            gender: query_gender,
            c1: query_category1,
            c2: query_category2,
            brand: query_brand,
            priceKeyword: selectedKeyword,
          })
        );
      } else {
        setKeywordUrl(
          url.label(LabelType.Combine, {
            gender: query_gender,
            c1: query_category1,
            c2: query_category2,
            brand: query_brand,
            keyword: selectedKeyword,
          })
        );
      }
    } else {
      if (action == 'price') {
        setKeywordUrl(
          url.label(LabelType.Combine, {
            gender: query_gender,
            c1: query_category1,
            c2: query_category2,
            priceKeyword: selectedKeyword,
          })
        );
      } else {
        setKeywordUrl(
          url.label(LabelType.Combine, {
            gender: query_gender,
            c1: query_category1,
            c2: query_category2,
            keyword: selectedKeyword,
          })
        );
      }
    }
  };

  return (
    <div className={styles.spu_display}>
      <header className={styles.header}>
        <h2 className={styles.brand}>{collab_designers}</h2>
        {spu_id ? (
          <h1 className={styles.name} onMouseMove={() => click_keyword(spu_id)} onMouseUp={() => click_keyword(spu_id)}>
            <Popover
              trigger="hover"
              content={
                <span>
                  <a href={keyword ? keywordUrl : '#'}>
                    {keyword} <SearchOutlined />
                  </a>{' '}
                  <span> {keywordTime}</span>
                </span>
              }
            >
              {name}
              <span style={{ display: 'none' }}>{spu_id}</span>
            </Popover>
            <a
              className={classNames(styles.name, styles.name_link)}
              target="_blank"
              href={webUrl.product({
                name,
                brand,
                gender: data.gender, // HACK: this gender is not always correct
                id: spu_id,
              })}
            >
              <IconFont type="icon-fenxiang" />
            </a>
          </h1>
        ) : (
          <h1 className={styles.name} onMouseMove={() => click_keyword(spu_id)} onMouseUp={() => click_keyword(spu_id)}>
            <Popover
              trigger="hover"
              content={
                <span>
                  <a href={keyword ? keywordUrl : '#'}>
                    {keyword} <SearchOutlined />
                  </a>{' '}
                  <span> {keywordTime}</span>
                </span>
              }
            >
              {name}
              <span style={{ display: 'none' }}>{spu_id}</span>
            </Popover>
          </h1>
        )}
        <h3
          className={styles.color}
          onMouseMove={() => click_keyword(spu_id)}
          onClick={stop()}
          onMouseUp={() => click_keyword(spu_id)}
        >
          <Popover
            trigger="hover"
            content={
              <span>
                <a href={keyword ? keywordUrl : '#'}>
                  {keyword} <SearchOutlined />
                </a>
                <span> {keywordTime}</span>
              </span>
            }
          >
            颜色：{color || ' '}
            <span style={{ display: 'none' }}>{spu_id}</span>
          </Popover>
        </h3>
        <h3
          className={styles.price}
          onMouseMove={() => click_keyword(spu_id, 'price')}
          onClick={stop()}
          onMouseUp={() => click_keyword(spu_id, 'price')}
        >
          <Popover
            trigger="hover"
            content={
              <span>
                <a href={keyword ? keywordUrl : '#'}>
                  {keyword} <SearchOutlined />
                </a>
                <span> {keywordTime}</span>
              </span>
            }
          >
            价格：{Math.ceil(price) || ''}
            <span style={{ display: 'none' }}>{spu_id}</span> {currency || ''}
          </Popover>
        </h3>
      </header>
      <Gallery
        data={images}
        isOfficial={'isOfficial' in data && data.isOfficial}
        onChangeImagesOrders={onChangeImagesOrders}
      />
      <hr />
      {data.composition ? <div className={styles.composition}>材质：{data.composition}</div> : null}
      <p
        className={styles.description}
        onMouseMove={() => click_keyword(spu_id)}
        onMouseUp={() => click_keyword(spu_id)}
      >
        <Popover
          trigger="hover"
          content={
            <span>
              <a href={keyword ? keywordUrl : '#'}>
                {keyword} <SearchOutlined />
              </a>{' '}
              <span> {keywordTime}</span>
            </span>
          }
        >
          NormalizedStyleId: {data.normalizedStyleId}
          <span style={{ display: 'none' }}>{spu_id}</span>
        </Popover>
      </p>
      <p
        className={styles.description}
        onMouseMove={() => click_keyword(spu_id)}
        onMouseUp={() => click_keyword(spu_id)}
      >
        <Popover
          trigger="hover"
          content={
            <span>
              <a href={keyword ? keywordUrl : '#'}>
                {keyword} <SearchOutlined />
              </a>{' '}
              <span> {keywordTime}</span>
            </span>
          }
        >
          {description}
          <span style={{ display: 'none' }}>{spu_id}</span>
        </Popover>
      </p>
    </div>
  );
});
