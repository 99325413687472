import React, { FC, useMemo, useCallback, useState } from 'react';
import { Checkbox, Empty, Modal, Button, message, Image } from 'antd';
import { useModal } from '@@/react-hooks';
import { Gallery } from '@/ui/gallery';
import { ItemSelect, OfferSelectableItem } from '../../item-select';
import commonStyles from '../../../common.module.less';
import { OfferGroupForVerification, OfferForVerification, SpuForVerification } from '@/interface/label';
import styles from './verify.module.less';
import * as apiService from '@/service';
import { useAccountInfo } from '@/store/account';
import { webUrl } from '@/url';
import { Gender } from '@reversible/schema';
import classNames from 'classnames';
import { StarFilled } from '@ant-design/icons';
import { url } from '@/url';
import { LabelType } from '@/enum';

export const CreateNewSpuProvider: FC<{
  selectedOfferGroups: OfferGroupForVerification[];
  spu: SpuForVerification;
  labelId: number;
  onCreateFinish(selectedOfferIds: string[]): void;
}> = ({ selectedOfferGroups, spu, labelId, onCreateFinish }) => {
  const [spuModalState, openSpuModal, closeSpuModal] = useModal<{
    selectedOffer: OfferGroupForVerification[];
  }>(null);
  const accountInfo = useAccountInfo();
  const onRequestCreate = useCallback(async () => {
    const closeMessage = message.loading('正在创建新spu', 0);
    console.log(spuModalState.data.selectedOffer);
    try {
      const {
        data: { new_spu },
      } = await apiService.postVerifyDataCreateSpu({
        labelId,
        spuId: spu.spuId,
        verifiedBy: accountInfo.username,
        spuImgUrl: spu.images[0], // must be a primary image
        errorOffers: selectedOfferGroups.map(({ offers, groupId }) => ({
          errorImgUrl: offers[0].imgUrl,
          groupId,
        })),
      });
      Modal.success({
        title: '创建成功',
        content: (
          <div>
            <div>创建新的spu: {new_spu.id}</div>
            <div className={styles.img_wrapper}>
              <Image className={styles.img} src={new_spu.img_url} />
              {new_spu.is_official ? <StarFilled className={styles.icon} /> : null}
              <div className={styles.verify_btn}>
                <a href={url.label(LabelType.Verify, { spuIds: [new_spu.id] })} target="_blank">
                  <button>verify</button>
                </a>
              </div>
            </div>
            <a
              target="_blank"
              href={webUrl.product({
                name: new_spu.name,
                brand: new_spu.brand,
                gender: new_spu.gender == 'women' ? Gender.Women : Gender.Men,
                id: new_spu.id,
              })}
              className={classNames(styles.name, styles.name_link)}
            >
              <h1 className={styles.name}>{new_spu.name}</h1>
            </a>
            <h3 className={styles.brand}>品牌：{new_spu.brand}</h3>
            <h3 className={styles.color}>颜色：{new_spu.color || ' '}</h3>
            <h3 className={styles.price}>
              价格：{Math.ceil(new_spu.price) || ''} {new_spu.currency || ''}
            </h3>
          </div>
        ),
      });
      const offer_group_ids = selectedOfferGroups.map(offer => offer.groupId);
      closeSpuModal();
      onCreateFinish(offer_group_ids);
    } catch (e) {
      message.error(e?.msg || e?.message || '提交失败，请重试');
    }
    closeMessage();
  }, [spuModalState]);

  return (
    <>
      <Button
        htmlType="submit"
        type="primary"
        size="small"
        className={styles.button}
        onClick={() => openSpuModal({ selectedOffer: selectedOfferGroups })}
      >
        创建新spu
      </Button>
      <Modal
        open={spuModalState.visible}
        onCancel={closeSpuModal}
        onOk={() => onRequestCreate()}
        okText="确定创建新spu"
        cancelText="取消"
        title="确定剔除当前offer并创建为新的spu？"
        width="560px"
      >
        <ItemSelect cols={2}>
          {selectedOfferGroups.map(offerGroup => {
            const data = offerGroup.offers[0];
            return (
              <OfferSelectableItem
                key={offerGroup.groupId}
                spu={spu}
                data={data}
                onClick={() => {}}
              ></OfferSelectableItem>
            );
          })}
        </ItemSelect>
      </Modal>
    </>
  );
};

export const OffersDisplay: FC<{
  spu: SpuForVerification;
  selectedOfferGroups: OfferGroupForVerification[];
  toggleOfferGroup(offer: OfferGroupForVerification): void;
  labelId?: number;
  onCreateNewFinish?(data: any): void;
}> = ({ spu, selectedOfferGroups, toggleOfferGroup, labelId, onCreateNewFinish }) => {
  const offerGroups = useMemo(() => {
    const groups: OfferGroupForVerification[] = [];
    for (const offerData of spu.offers) {
      const foundGroup = groups.find(({ groupId }) => groupId === offerData.groupId);
      if (!foundGroup) {
        groups.push({
          groupId: offerData.groupId,
          offers: [offerData],
        });
      } else {
        foundGroup.offers.push(offerData);
      }
    }
    return groups;
  }, [spu]);

  const [zoomModalState, openZoomModal, closeZoomModal] = useModal<OfferForVerification>(null);

  const selectedCount = selectedOfferGroups.length;
  const [offersData, setoffersData] = useState(offerGroups);

  if (!offerGroups.length) {
    return (
      <div className={commonStyles.vertical_container}>
        <div className={commonStyles.empty}>
          <Empty description="没有找到可选 Offer" />
        </div>
      </div>
    );
  }

  const onCreateFinish = useCallback(selectedOfferIds => {
    setoffersData(prev => prev.filter(({ groupId }) => !selectedOfferIds.includes(groupId)));
    onCreateNewFinish([]);
  }, []);

  return (
    <div className={commonStyles.vertical_container}>
      <header className={commonStyles.header}>
        <h3 className={commonStyles.title}>
          请勾选不属于当前商品的offer{selectedCount ? <span>(已选中 {selectedCount} 项)</span> : null}
        </h3>
        {selectedCount ? (
          <CreateNewSpuProvider
            selectedOfferGroups={selectedOfferGroups}
            spu={spu}
            labelId={labelId}
            onCreateFinish={onCreateFinish}
          ></CreateNewSpuProvider>
        ) : null}
      </header>
      <ItemSelect cols={2}>
        {offersData.map(offerGroup => {
          const data = offerGroup.offers[0];
          const checked = selectedOfferGroups.includes(offerGroup);
          const onClick = () => toggleOfferGroup(offerGroup);
          const onZoom = () => openZoomModal(data);
          return (
            <OfferSelectableItem
              key={offerGroup.groupId}
              checked={checked}
              spu={spu}
              data={data}
              onClick={onClick}
              onZoom={onZoom}
            >
              <Checkbox className={commonStyles.checker} checked={checked} />
            </OfferSelectableItem>
          );
        })}
      </ItemSelect>
      <Modal
        open={zoomModalState.visible}
        onCancel={closeZoomModal}
        title={zoomModalState.data?.offerName}
        footer={null}
        width="33vw"
        mask={false}
        maskClosable={false}
      >
        {zoomModalState.visible ? <Gallery data={zoomModalState.data.images} /> : null}
      </Modal>
    </div>
  );
};
