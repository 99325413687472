import React, { FC, useEffect, useRef, useState } from 'react';
import { Alert, message, Space, Spin, Select, Modal, Input } from 'antd';
import { useFlow, CallNext, useUrlQueryState, useSuperState, GetNext } from '@@/react-hooks';
import { MaybePromise } from '@@/util-types';
import { SpuStatus } from '@reversible/schema';
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import * as apiService from '@/service';
import { SimilarSpus } from '../verify/component/similar-spus';
import { CombineProduct, SimilarProduct } from '@/interface/label';
import { PureAntdLoading } from '@/ui/pure-antd-loading';
import { LoadableChangeAntdButton } from '@/ui/loadable-antd-button';
import { LabelLayout, LabelLayoutHeader } from '../layout';
import { GetProducts, GetOfficialRetailers } from '@/service/interface';
import { LabelUrlQuery } from '@/interface/url';
import { LabelType, LabelSourceEnum, SortKey } from '@/enum';
import commonStyles from '../../common.module.less';
import { ALL_VALUE } from '@/const';
import { SpuDisplay } from '../spu-display';
import { useScrollToBottomHook } from './scroll_monitor';
import { record_work } from '../../index';

const awaitCombineConfirm = msg =>
  new Promise<boolean>(resolve => {
    let comfirm_msg = '商品非同一个品牌，是否强制合并?';
    if (msg === 'Both spu has user interactions') {
      comfirm_msg = '商品存在用户行为，是否强制合并?';
    }
    Modal.confirm({
      title: comfirm_msg,
      icon: <ExclamationCircleFilled />,
      content: '',
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    });
  });

/**
 * submission data for correction labeling
 */
interface VerificationSubmission {
  spuIdsToCombine: number[]; // spuIds to combine
  loadMore: boolean;
  sort: number;
  nameKeyword?: string;
}

interface LoadInfo {
  showmore: boolean;
  loading: boolean;
  error: boolean;
  deleteSpu: number[];
}

export type Action =
  | {
      type: 'loadMore';
      payload: VerificationSubmission;
    }
  | {
      type: 'submit';
      payload: VerificationSubmission;
    }
  | {
      type: 'sortBy';
      payload: VerificationSubmission;
    }
  | {
      type: 'filterNameKeyword';
      payload: VerificationSubmission;
    };

const CombineLabelCore: FC<{
  loadInfo: LoadInfo;
  combineProduct: CombineProduct;
  dispatch(action: Action): MaybePromise<void>;
}> = ({ loadInfo, combineProduct, dispatch, ...filterProps }) => {
  // 标注数据
  const [labelState, setLabelState] = useSuperState<VerificationSubmission>({
    spuIdsToCombine: [],
    loadMore: true,
    sort: 0,
    nameKeyword: '',
  });
  const [combineLoading, setCombineLoading] = useState(false);
  const sort = [
    { label: 'Featured', key: SortKey.Featured },
    { label: 'Price Low to High', key: SortKey.LowPrice },
    { label: 'Price High to Low', key: SortKey.HighPrice },
    { label: 'Sale High to Low', key: SortKey.SaleHighPrice },
    { label: 'Latest', key: SortKey.Latest },
    { label: 'Most Popular', key: SortKey.Popular },
    { label: 'Low Retail Price', key: SortKey.LowOfferPrice },
    { label: 'High Retail Price', key: SortKey.HighOfferPrice },
  ];

  // initialize labeling state while spu mutated
  useEffect(() => {
    setLabelState({
      spuIdsToCombine: [],
      loadMore: true,
      sort: 0,
      nameKeyword: '',
    });
  }, []);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key.toUpperCase() === 'C' && event.target.nodeName != 'INPUT') {
        setCombineLoading(true);
        const result = dispatch({ type: 'submit', payload: labelState });
        if (result === undefined) {
          setCombineLoading(false);
        }
        if (result instanceof Promise) {
          result.finally(() => {
            setLabelState({ spuIdsToCombine: [] });
            setCombineLoading(false);
          });
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [labelState]);

  const el = useRef();
  useScrollToBottomHook(el, () => dispatch({ type: 'loadMore', payload: labelState }));

  const onChangeSortBy = (sort: number) => {
    setLabelState(prev => ({
      ...prev,
      sort,
    }));
    dispatch({
      type: 'sortBy',
      payload: { loadMore: labelState.loadMore, spuIdsToCombine: labelState.spuIdsToCombine, sort },
    });
  };

  // 内容节点
  const contentNode = (() => {
    const { showmore, loading, error, deleteSpu } = loadInfo;
    const onChangeSelectedSpuIds = (spuIdsToCombine: number[]) =>
      setLabelState(prev => ({
        ...prev,
        spuIdsToCombine: spuIdsToCombine.filter(id => !deleteSpu.includes(id)),
      }));

    if (labelState.nameKeyword) {
      const filterNameList = [];
      for (const product of combineProduct.keywordProducts) {
        if (
          (product.name && product.name.toLowerCase().includes(labelState.nameKeyword.toLowerCase())) ||
          (product.color && product.color.toLowerCase().includes(labelState.nameKeyword.toLowerCase()))
        ) {
          filterNameList.push(product);
        }
      }
      combineProduct.keywordProducts = filterNameList;
    }
    const { keywordProducts } = combineProduct;
    const spuId = labelState.spuIdsToCombine.slice(-1)[0];
    const spuItem = keywordProducts.filter(value => (value.id === spuId ? value : ''))[0];
    if (spuItem) {
      sessionStorage.setItem('styleId', spuItem.normalizedStyleId);
    }
    return (
      <>
        <div className={commonStyles.left_of_2_2}>
          <div className={commonStyles.vertical_container}>
            <header className={commonStyles.header}>
              <h3 className={commonStyles.title}>当前商品</h3>
              <span className={commonStyles.header_addon}>
                {labelState.spuIdsToCombine.length === 0 ? (
                  <button disabled>clear</button>
                ) : (
                  <button
                    onClick={() => {
                      setLabelState({ spuIdsToCombine: [] });
                    }}
                  >
                    clear(已选中 {labelState.spuIdsToCombine.length} 项)
                  </button>
                )}
              </span>
            </header>
            {spuItem ? <SpuDisplay data={spuItem} /> : <br />}
          </div>
        </div>
        <div className={commonStyles.right_of_6} ref={el}>
          <div>
            <SimilarSpus
              data={combineProduct}
              selectedSpuIds={labelState.spuIdsToCombine}
              onChangeSelectedSpuIds={onChangeSelectedSpuIds}
            />
          </div>
          {loading ? (
            <div className={commonStyles.show_more}>
              加载中{' '}
              <Space size="middle">
                <Spin size="small" />
              </Space>
            </div>
          ) : error ? (
            <div className={commonStyles.show_more} onClick={() => dispatch({ type: 'loadMore', payload: labelState })}>
              加载失败, 点击重新加载...
            </div>
          ) : showmore ? (
            <div className={commonStyles.show_more} onClick={() => dispatch({ type: 'loadMore', payload: labelState })}>
              加载更多 {'>'}
            </div>
          ) : (
            <div className={commonStyles.show_more}>没有更多数据</div>
          )}
        </div>
      </>
    );
  })();

  return (
    <LabelLayout>
      <LabelLayoutHeader {...filterProps} type={LabelType.Verify}>
        <div className={commonStyles.sort_by}>
          <Input
            placeholder="请填写name/color关键词"
            onChange={e => setLabelState({ nameKeyword: e.target.value })}
            onPressEnter={() => dispatch({ type: 'filterNameKeyword', payload: labelState })}
          />
        </div>
        <div className={commonStyles.sort_by}>
          sort by:{' '}
          <Select placeholder="Featured" onChange={onChangeSortBy}>
            {sort.map(data => (
              <Select.Option key={data.key} value={data.key}>
                {data.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <LoadableChangeAntdButton
          onClick={() => dispatch({ type: 'submit', payload: labelState })}
          onChange={() => {
            setLabelState({ spuIdsToCombine: [] });
          }}
        >
          {combineLoading ? <LoadingOutlined /> : null} 合并(C)
        </LoadableChangeAntdButton>
      </LabelLayoutHeader>
      {contentNode}
    </LabelLayout>
  );
};

interface VerificationState {
  queue: SimilarProduct[];
  index: number;
  showmore: boolean;
  deleteSpu: number[];
  official: number[];
  designers: {};
  sort: number;
}

export const CombineLabeler: FC = () => {
  const [{ gender, c1, c2, brand, keyword, priceKeyword }] = useUrlQueryState<LabelUrlQuery>();

  const [
    {
      data: { queue, index, showmore, deleteSpu },
      loading,
      ready,
      error,
    },
    dispatch,
  ] = useFlow<VerificationState, Action | void>(
    {
      queue: [],
      index: 0,
      showmore: true,
      deleteSpu: [],
      official: [],
      designers: {},
      sort: 0,
    },
    function* ({ call, put, get, getCount }, action) {
      const {
        index: currentIndex,
        queue: currentQueue,
        showmore: showMore,
        deleteSpu: spuDelete,
        official,
        designers,
        sort,
      }: GetNext<VerificationState> = yield get();
      let nextQueue = currentQueue;
      const deleteSpu = spuDelete;
      let officialRetailers = official;
      const allDesigners = designers;
      let currentPage = currentIndex;
      let sortBy = sort;
      let show = showMore;

      if (official.length === 0) {
        const {
          data: { official_retailers },
        }: CallNext<GetOfficialRetailers> = yield call(apiService.getOfficialRetailers);
        officialRetailers = official_retailers;
      }
      if (Object.keys(designers).length === 0) {
        const brands = yield call(apiService.getDesigners);
        for (const designer of brands.data.all) {
          allDesigners[designer.toLowerCase()] = designer;
        }
      }

      if (action && action.type === 'submit') {
        // 提交
        const { spuIdsToCombine } = action.payload;

        if (spuIdsToCombine.length) {
          if (spuIdsToCombine.length < 2) {
            message.error('当前没有可合并商品,请重新选择');
            return;
          }
          const spuId = spuIdsToCombine.slice(-1)[0];
          const spuIdsToCombineOther = spuIdsToCombine.slice(0, -1);

          record_work(LabelType.Combine, JSON.stringify(spuIdsToCombine), gender, c1, c2);
          let forceCombine = false;
          let forceCombineBrand = false;
          const spusToDelete = yield call(async () => {
            const list: number[] = [];
            let spuId0 = spuId;
            // async reduce
            for (const spuId1 of spuIdsToCombineOther) {
              const baseQuery = {
                keyword: priceKeyword || keyword,
                source: LabelSourceEnum.KEYWORD_SEARCH,
                spuId0,
                spuId1,
                forceCombine,
                forceCombineBrand,
              };
              const force_combine = async baseQuery => {
                try {
                  const {
                    data: { toKeep, toDelete, prioritySpuId },
                  } = await apiService.postCombineProduct({
                    ...baseQuery,
                  });
                  if (toDelete === prioritySpuId) {
                    // 如果toDelete优先级更高, 保留toDelete的spu信息，keep的id
                    const randomId = Math.floor(Math.random() * Math.pow(10, 9));
                    currentQueue.filter(spu => (spu.id === toKeep ? (spu.id = randomId) : null));
                    currentQueue.filter(spu => (spu.id === toDelete ? (spu.id = toKeep) : null));
                    currentQueue.filter(spu => (spu.id === randomId ? (spu.id = toDelete) : null));
                  }
                  list.push(toDelete);
                  spuId0 = toKeep;
                } catch (e) {
                  if (
                    e &&
                    (e.msg === 'Both spu has user interactions' || e.msg === 'Both spu are not the same brand')
                  ) {
                    const result = await awaitCombineConfirm(e.msg);
                    if (result) {
                      // 强制合并
                      if (e.msg === 'Both spu has user interactions') {
                        forceCombine = true;
                        forceCombineBrand = true;
                        baseQuery.forceCombine = true;
                        baseQuery.forceCombineBrand = true;
                      } else {
                        forceCombine = false;
                        forceCombineBrand = true;
                        baseQuery.forceCombine = false;
                        baseQuery.forceCombineBrand = true;
                      }
                      await force_combine(baseQuery);
                    } else {
                      return list;
                    }
                  } else {
                    message.error(e?.msg || '提交失败，请重试');
                    return list;
                  }
                }
              };
              await force_combine(baseQuery);
            }
            return list;
          });
          nextQueue = currentQueue.filter(spu => !spusToDelete.includes(spu.id)).map(item => ({ ...item }));
          for (const spu_id of spusToDelete) {
            deleteSpu.push(spu_id);
          }
          message.success('合并已完成');
        } else {
          message.error('请选择需合并商品');
          return;
        }
        yield put({
          queue: nextQueue,
          index: currentIndex,
          showmore: show,
          deleteSpu,
          official: officialRetailers,
          designers: allDesigners,
          sort: sortBy,
        });
        return;
      }

      if (action && action.type === 'sortBy') {
        // sort
        const { sort } = action.payload;
        currentPage = -1;
        sortBy = sort;
        nextQueue = [];
        show = true;
      }

      if (!show) {
        yield put({
          queue: nextQueue,
          index: currentIndex,
          showmore: show,
          deleteSpu,
          official: officialRetailers,
          designers: allDesigners,
          sort: sortBy,
        });
        return;
      }
      // 拉取新数据
      const count = yield getCount();
      const page = count === 0 ? currentPage : currentPage + 1;
      const pnl_brand = () => {
        if (brand && typeof brand === 'string' && Object.keys(allDesigners).includes(brand.toLowerCase())) {
          return allDesigners[brand.toLowerCase()];
        }
        const brand_query = [];
        for (let i = 0; i < brand.length; i++) {
          const b = Object.keys(allDesigners).includes(brand[i].toLowerCase())
            ? allDesigners[brand[i].toLowerCase()]
            : brand[i];
          brand_query.push(b);
        }
        return brand_query;
      };
      const { data }: CallNext<GetProducts> = yield call(apiService.getProducts, {
        gender,
        category1: c1,
        category2: c2 === ALL_VALUE ? undefined : c2,
        brand: typeof brand === 'undefined' ? brand : pnl_brand(),
        keyword,
        lowestPublicPrice: priceKeyword ? Number(priceKeyword) : undefined,
        highestPublicPrice: priceKeyword ? Number(priceKeyword) : undefined,
        offset: page * 95,
        limit: 96,
        sort: sortBy,
      });
      let showmore = data.spuList.length > 0;
      showmore = page === 0 && data.total <= 96 ? false : showmore;
      const queue = nextQueue;
      const queuedIds = queue.map(old_spu => old_spu.id);
      for (const spu of data.spuList) {
        if (spu.spuStatus != SpuStatus.Deleted) {
          spu.isOfficial = false;
          if (officialRetailers.includes(spu.retailer)) {
            spu.isOfficial = true;
          }
          if (!queuedIds.includes(spu.id) && !deleteSpu.includes(spu.id)) {
            queue.push(spu);
          }
        }
      }

      yield put({
        queue,
        index: page,
        showmore,
        deleteSpu,
        official: officialRetailers,
        designers: allDesigners,
        sort: sortBy,
      });
    }
  );

  useEffect(() => {
    dispatch();
    sessionStorage.setItem('Tabs_number', '4');
    sessionStorage.setItem('styleId', '');
  }, []);

  if (!queue.length) {
    if (loading) {
      return <PureAntdLoading size="large" tip="正在获取商品数据..." />;
    }

    if (error) {
      console.log('error', error);
      const errorDescription = error?.msg || error?.data || '未知错误';
      return <Alert message="获取商品数据错误" showIcon description={errorDescription} type="error" />;
    }

    if (!ready) {
      // 只会在初始时刻出现的状态
      return null;
    }
  }

  const currentItem = { keywordProducts: queue };
  const loadInfo = { showmore, loading, error, deleteSpu };

  return <CombineLabelCore loadInfo={loadInfo} combineProduct={currentItem} dispatch={dispatch} />;
};
