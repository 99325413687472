import React, { FC, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Typography, FormItemProps, Tabs, Dropdown, Menu, Space } from 'antd';
import { useCategoryCascaderOptions } from '@/hooks/use-category-cascader-options';
import { Gender, LabelType, availableLabelTypes } from '@/enum';
import { url } from '@/url';
import { LeftOutlined, FileExcelOutlined } from '@ant-design/icons';
import * as apiService from '@/service';
import { useAccountInfo } from '@/store/account';
import { CallNext, useFlowEffect } from '@@/react-hooks';
import { GetDesigners, GetLabelerList, GetAllRetailers } from '@/service/interface';
import styles from './home.module.less';
import { homeContext } from './context';
import { ClassifyForm } from './classify-form';
import { VerifyForm } from './verify-form';
import { SpecifiedVerifyForm } from './specified-verify-form';
import { CombineForm } from './combine-form';
import { CombineStyleIdForm } from './combine-styleid-form';
import { DownOutlined } from '@ant-design/icons';

const { Title } = Typography;

const COMMON_FORM_ITEM_PROPS: FormItemProps = {
  labelAlign: 'left',
  labelCol: {
    span: 6,
  },
};

export const Home: FC = () => {
  const { userStatus, username } = useAccountInfo();
  const tabs_number = sessionStorage.getItem("Tabs_number")

  const categoryCascaderOptionsState = useCategoryCascaderOptions();

  const genderDesignerOptionsDictState = useFlowEffect({
    [Gender.Men]: [],
    [Gender.Women]: [],
  }, function* ({ call, put }) {
    const { data }: CallNext<GetDesigners> = yield call(apiService.getDesigners);

    yield put(data);
  }, []);

  const genderRetailersOptionsDictState = useFlowEffect([] as string[], function* ({ call, put }) {
    const { data: { retailers } }: CallNext<GetAllRetailers> = yield call(apiService.getAllRetailers);
    yield put(retailers);
  }, []);

  const logout = useCallback(() => {
    apiService.logout();
    location.reload();
  }, []);

  const verifiable = useMemo(() => availableLabelTypes(userStatus).includes(LabelType.Verify), [userStatus]);

  const labelerListState = useFlowEffect([] as string[], function* ({ put, call }) {
    if (verifiable) {
      const { data }: CallNext<GetLabelerList> = yield call(apiService.getLabelerList);

      yield put(data);
    }
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Typography.Link href={url.report()}> <FileExcelOutlined /> 查看报告</Typography.Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Typography.Link href={url.details()}><FileExcelOutlined /> 查看详情</Typography.Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Typography.Link href={url.category()}><FileExcelOutlined /> 查看类目</Typography.Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Typography.Link href={url.online()}><FileExcelOutlined /> 查看上下线</Typography.Link>
      </Menu.Item>
      <Menu.Item key="5">
        <Typography.Link href={url.refresh()}><FileExcelOutlined /> 查看刷新率</Typography.Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <homeContext.Provider value={{
      commonFormItemProps: COMMON_FORM_ITEM_PROPS,
      labelerListState,
      genderDesignerOptionsDictState,
      categoryCascaderOptionsState,
      genderRetailersOptionsDictState,
    }} >
      <div className={styles.home}>
        <div className={styles.top_tab}>
          <a className={styles.logout_link} onClick={logout}>
            <LeftOutlined />
            登出账号「{username}」
          </a>
          {verifiable && ['lixiang@reversible', 'deng@reversible', 'wang@reversible'].includes(username) ? (
            <Dropdown overlay={menu} trigger={['click']} className={styles.export_link} >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  报告查询 <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          ) : verifiable ? (
            <Link className={styles.export_link} to={url.report()}>
              <FileExcelOutlined /> 查看报告
            </Link>
          ) : null}
        </div>
        <div className={styles.form_wrapper}>
          <Title level={2}>REVERSIBLE 标注</Title>
          {verifiable ? (
            <Tabs size="large" centered defaultActiveKey={tabs_number ? tabs_number : "1"}
              onTabClick={(key) => { sessionStorage.setItem("Tabs_number", key) }}>
              <Tabs.TabPane key="1" tab={<div className={styles.tab}>标注</div>}>
                <ClassifyForm />
              </Tabs.TabPane>
              <Tabs.TabPane key="2" tab={<div className={styles.tab}>审核</div>}>
                <VerifyForm />
              </Tabs.TabPane>
              <Tabs.TabPane key="3" tab={<div className={styles.tab}>指定商品</div>}>
                <SpecifiedVerifyForm />
              </Tabs.TabPane>
              <Tabs.TabPane key="4" tab={<div className={styles.tab}>指定关键词</div>}>
                <CombineForm />
              </Tabs.TabPane>
              <Tabs.TabPane key="5" tab={<div className={styles.tab}>StyleID</div>}>
                <CombineStyleIdForm />
              </Tabs.TabPane>
            </Tabs>
          ) : (
            <ClassifyForm />
          )}
        </div>
      </div>
    </homeContext.Provider>
  );
};
