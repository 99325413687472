import React, { FC, ReactNode } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { LabelType } from '@/enum';
import { url } from '@/url';
import { useAccountInfo } from '@/store/account';
import { useAccountLabelInfo } from '@/store/account-label-info';
import { useUrlQueryState } from '@@/react-hooks';
import { LabelUrlQuery } from '@/interface/url';
import styles from './index.module.less';
import { useHistory } from 'react-router-dom';

export const LabelLayout: FC<{
  children?: ReactNode;
}> = ({ children }) => <div className={styles.layout}>{children}</div>;

export const LabelLayoutHeader: FC<{
  type: LabelType;
  children?: ReactNode;
}> = ({ children, type }) => {
  const { username } = useAccountInfo();

  const [labelInfo] = useAccountLabelInfo();

  const history = useHistory();
  const current_tabs = history['location']['pathname'];
  const [{ labeler, gender, c1, c2, brand, spuIds, retailer, keyword }] = useUrlQueryState<LabelUrlQuery>();
  if (current_tabs == '/label/verify' && !spuIds) {
    sessionStorage.setItem('verify_category', String([gender, c1, c2]));
    sessionStorage.setItem('verify_labeler', String(labeler));
    sessionStorage.setItem('verify_brand', String(brand));
    sessionStorage.setItem('verify_retailer', String(retailer));
  } else if (current_tabs == '/label/classify') {
    sessionStorage.setItem('classify_category', String([gender, c1, c2]));
    sessionStorage.setItem('classify_retailer', String(retailer));
    sessionStorage.setItem('classify_brand', String(brand));
  } else if (current_tabs == '/label/combine') {
    sessionStorage.setItem('combine_category', String([gender, c1, c2]));
    sessionStorage.setItem('combine_brand', String(brand));
    sessionStorage.setItem('combine_keyword', String(keyword));
  } else if (current_tabs == '/label/styleId') {
    sessionStorage.setItem('styleId_category', String([gender, c1, c2]));
    sessionStorage.setItem('styleId_brand', String(brand));
  }

  return (
    <header className={styles.header}>
      <Link className={styles.link} to={url.home()}>
        <LeftOutlined /> 返回首页
      </Link>
      <span className={styles.info}>
        {spuIds ? (
          <>指定 SPU ID：{spuIds.join(', ')}</>
        ) : (
          <>
            类别：{gender} / {c1} {c2 ? `/ ${c2}` : ''}
          </>
        )}
      </span>
      <span className={styles.info}>账号：{username}</span>
      {labeler ? <span className={styles.info}>指定标注账号：{labeler}</span> : null}
      {type === LabelType.Classify ? (
        labelInfo.labeler ? (
          <span className={styles.info}>已标注：{labelInfo.labeledCount}</span>
        ) : null
      ) : labelInfo.verifier ? (
        <>
          <span className={styles.info}>已审核：{labelInfo.verifiedCount}</span>
          <span className={styles.info}>待审核：{labelInfo.pendingVerify}</span>
          <span className={styles.info}>待标注：{labelInfo.pendingLabel}</span>
        </>
      ) : null}
      <span className={styles.spaceholder} />
      {children}
    </header>
  );
};
