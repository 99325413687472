import { createCacher, mapMaybePromise, ObjectUtils } from '@@/utils';
import {
  headers,
  httpFetch,
  httpOnion,
  HttpMiddleware,
  httpUrl,
  jsonBody,
  method,
  onResolve,
  rejectOn,
  cache,
} from '@@/http';
import { message } from 'antd';
import { ApiResponseCode } from '@/enum';
import * as t from './interface';
import { DEPLOY_ENV } from '@/env';
import { deepMapCamelToSnake, deepMapSnakeToCamel } from '@/util/format';

// token cacher
const tokenCacher = createCacher('token', 'local');

const preBaseMws = [
  headers(() => {
    const currentToken = tokenCacher.get();
    return currentToken
      ? {
          Authorization: currentToken,
        }
      : {};
  }),
];

const postBaseMws = [
  rejectOn(res => 'resolve' in res && typeof res.resolve === 'object' && !res.resolve?.success),
  httpFetch(),
];

/**
 * auth middleware for unauth callback
 */
const auth: HttpMiddleware = async (req, next) => {
  const res = await next(req);

  if ('reject' in res && res.reject?.code === ApiResponseCode.Forbidden) {
    message.warning('登录状态已过期，请重新登录', 1).then(() => window.location.reload());
  }

  return res;
};

/**
 * test only message
 */
const testAlert: HttpMiddleware = async (req, next) => {
  const res = await next(req);

  if ('resolve' in res && res.resolve?.code === ApiResponseCode.Accepted) {
    message.warning('此次提交仅供测试，不会对数据产生实质影响');
  }

  return res;
};

/**
 * adapt camel cases and snake cases
 * @returns
 */
export const adaptCase = (): HttpMiddleware => (req, next) =>
  mapMaybePromise(next(ObjectUtils.immutableSet(req, ['params'], deepMapCamelToSnake)), res =>
    'resolve' in res ? ObjectUtils.immutableSet(res, ['resolve', 'data'], deepMapSnakeToCamel) : res
  );

const TARGET = {
  local: '/api',
  dev: 'https://api.reversible.dev/api',
  live: 'https://api.reversible.live/api',
  prod: 'https://api.reversible.com/api',
}[DEPLOY_ENV];

const OM_TARGET = {
  local: '/api',
  dev: 'https://manager-api.reversible.dev/api',
  live: 'https://manager-api.reversible.live/api',
  prod: 'https://manager-api.reversible.com/api',
}[DEPLOY_ENV];

const url = httpUrl(TARGET);
const omUrl = httpUrl(OM_TARGET);

const f = (...mws: HttpMiddleware[]) => httpOnion(...preBaseMws, ...mws, ...postBaseMws).fork();

// login & account
export const login: t.Login = f(
  method('post'),
  url('/label/login'),
  jsonBody(),
  onResolve(({ data }) => {
    tokenCacher.set(data);
  })
);

export const logout: t.Logout = tokenCacher.clear;

export const getUserInfo: t.GetUserInfo = f(adaptCase(), url('/label/login'));

// product apis
export const getCategories: t.GetCategories = f(cache(), url('/data/category'));

export const getProducts: t.GetProducts = f(omUrl('/v1/product', true));

export const getProductsDetails: t.GetProductsDetails = f(url('/product/details', true));

export const getDesigners: t.GetDesigners = f(url('/data/brand'));

export const getDesignerVerifyCount: t.GetDesignerVerifyCount = f(url('/label/verify/count', true));

// labeling apis
export const getOfficialRetailers: t.GetOfficialRetailers = f(auth, url('/label/official', true));

export const getAllRetailers: t.GetAllRetailers = f(auth, url('/label/retailers', true));

export const getSizeOptions: t.GetSizeOptions = f(auth, url('/label/size/options', true));

export const getClassifierLabelData: t.GetClassifierLabelData = f(auth, url('/label/classify', true));

export const postClassifierLabelData: t.PostClassifierLabelData = f(
  auth,
  testAlert,
  method('post'),
  url('/label/classify'),
  jsonBody()
);

export const postCombineProduct: t.PostCombineProduct = f(auth, method('post'), url('/label/combine'), jsonBody());

export const putProducts: t.PutProducts = f(
  auth,
  method('put'),
  url(({ spuId }) => `/label/spu/${spuId}`),
  jsonBody(({ spuId, ...rest }) => ({
    ...rest,
  }))
);

// verify
export const getVerifyData: t.GetVerifyData = f(
  auth,
  url('/label/verify', params => ({
    ...params,
    size: 3,
  }))
);

export const getSimilarSpuData: t.GetSimilarSpuData = f(
  auth,
  url('/label/similar_styleId', params => ({
    ...params,
    size: 5,
  }))
);
export const postSimilarSpuData: t.PostSimilarSpuData = f(
  auth,
  testAlert,
  method('post'),
  url('/label/similar_styleId'),
  jsonBody()
);

export const postVerifyData: t.PostVerifyData = f(auth, testAlert, method('post'), url('/label/verify'), jsonBody());

export const postVerifyDataCreateSpu: t.PostVerifyDataCreateSpu = f(
  auth,
  testAlert,
  method('post'),
  url('/label/verify/new-spu'),
  jsonBody()
);

export const getLabelerList: t.GetLabelerList = f(url('/label/verify/labeler'));

export const exportReport: t.ExportReport = f(url('/label/report', true));
export const exportLabelCategory: t.ExportLabelCategory = f(url('/label/label_categroy', true));
export const exportLabelTime: t.ExportLabelTime = f(url('/label/label_time', true));
export const exportLabelDetails: t.ExportLabelDetails = f(url('/label/label_details', true));
export const exportLabelRefresh: t.ExportLabelRefresh = f(url('/label/label_refresh', true));

export const postRecord: t.postRecord = f(auth, method('post'), url('/label/record'), jsonBody());

export const putCrawlerInfo: t.putCrawlerInfo = f(
  auth,
  method('put'),
  url(({ crawlerProductId }) => `/label/crawler/info/${crawlerProductId}`),
  jsonBody(({ crawlerProductId, ...rest }) => ({
    ...rest,
  }))
);
